import {
  CREATE_WITHDRAW_SUCCESS,
  GET_USER_WITHDRAWS_SUCCESS,
  GET_WITHDRAWS_SUCCESS,
} from "../types";

const initialState = () => ({
  withdraw: {},
  withdraws: [],
});

const withdrawReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case CREATE_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdraw: payload.withdraw,
      };
    case GET_WITHDRAWS_SUCCESS:
      return {
        ...state,
        withdraws: payload.withdraws,
      };
    case GET_USER_WITHDRAWS_SUCCESS:
      return {
        ...state,
        userWithdrawals: payload.withdraws,
      };

    default:
      return state;
  }
};

export default withdrawReducer;
