import React, { useEffect } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { FormError } from "../../../components/FormError";
import { FormButtonPrimary } from "../../../components/buttons";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { clearLoginSuccess, loginRequest } from "../../../redux/action";

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { loginSuccess, loginError, loginLoading, user } = useSelector(
    (state) => {
      const {
        success: { login: loginSuccess },
        errors: { login: loginError },
      } = state.ajaxStatuses;

      const { loginLoading } = state.loadingIndicator;

      const { user } = state.userData;

      return {
        loginSuccess,
        loginError,
        loginLoading,
        user,
      };
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("must be a valid email")
        .required("Enter email"),
      password: yup.string().required("Enter your password"),
    }),

    onSubmit: (prop) => {
      console.log(prop);
      dispatch(loginRequest(prop));
    },
  });

  useEffect(() => {
    if (loginError) {
      toast.error(loginError, {
        duration: 3000,
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (loginSuccess) {
      // toast.success(loginSuccess, {
      //   duration: 3000,
      // });

      history.push("/admin/dashboard");

      dispatch(clearLoginSuccess());
    }
  }, [loginSuccess]);

  return (
    <WebPageWrapper>
      <WebPageNav />

      <div className=" pt-20 md:pt-40 pb-32 px-4">
        <div className="max-w-xl mx-auto profit-calculator-wrapper relative z-10 py-10">
          <h1 className="text-center text-white text-2xl md:text-5xl font-head font-semibold max-w-2xl mx-auto">
            Admin <br /> <span className="text-primary">Login</span>
          </h1>

          <div className=" border-t-2 border-formBorder w-full px-4 md:px-32 mt-8">
            <form className="pt-8 flex flex-col gap-y-3">
              <div className="flex items-center justify-center gap-x-2">
                <h5 className="font-body text-white">Email:</h5>
                <div>
                  <input
                    type="text"
                    className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                    placeholder="Username or email"
                    {...formik.getFieldProps("email")}
                    name="email"
                  />
                  {formik.errors.email && formik.touched.email && (
                    <FormError message={formik.errors.email} />
                  )}
                </div>
              </div>
              <div className="flex items-center gap-x-2">
                <h5 className="font-body text-white">Password:</h5>
                <div>
                  <input
                    type="text"
                    className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                    name="password"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <FormError message={formik.errors.password} />
                  )}
                </div>
              </div>

              <div className="w-32 flex justify-center ml-20">
                <div></div>
                <FormButtonPrimary
                  action={formik.handleSubmit}
                  loading={loginLoading}
                  disabled={!formik.isValid}
                  text={"Login"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Login;
