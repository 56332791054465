import React, { useEffect } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import {
  getUserInvestmentsRequest,
  getUserRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { UserProfileForm } from "../../../components/Forms/userProfileForm";
import { AdminDashboardNav } from "../../../components/Nav/adminDashboardNav";

const EditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    getUserSuccess,
    getUserError,
    getUserLoading,
    user,
    singleUser,
    updateUserSuccess,
    updateUserError,
    updateUserLoading,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
  } = useSelector((state) => {
    const {
      success: {
        getUser: getUserSuccess,
        updateUser: updateUserSuccess,
        getUserInvestments: getUserInvestmentsSuccess,
      },
      errors: {
        getUser: getUserError,
        updateUser: updateUserError,
        getUserInvestments: getUserInvestmentsError,
      },
    } = state.ajaxStatuses;

    const { getUserLoading, updateUserLoading, getUserInvestmentsLoading } =
      state.loadingIndicator;

    const { user, singleUser } = state.userData;
    const { investments } = state.investmentData;

    return {
      getUserSuccess,
      getUserError,
      getUserLoading,
      singleUser,
      user,
      updateUserSuccess,
      updateUserError,
      updateUserLoading,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
    };
  });

  useEffect(() => {
    dispatch(getUserRequest(id));
    dispatch(getUserInvestmentsRequest(id));
  }, [getUserRequest, getUserInvestmentsRequest]);

  const getPageUser = (_id) => {
    dispatch(getUserRequest(_id));
    dispatch(getUserInvestmentsRequest(_id));
  };

  useEffect(() => {
    if (getUserError) {
      toast.error(getUserError, {
        duration: 3000,
      });
    }
  }, [getUserError]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Edit User"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <AdminDashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-head text-xl md:text-2xl text-center">
            User Profile Form
          </h3>
          <UserProfileForm
            user={singleUser}
            loading={getUserLoading}
            getPageUser={getPageUser}
            getUserInvestmentsLoading={getUserInvestmentsLoading}
          />
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default EditUser;
