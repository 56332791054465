export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

export const LOGOUT = "LOGOUT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CLEAR_REGISTER_SUCCESS = "CLEAR_REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_LOADING = "REGISTER_LOADING";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CLEAR_LOGIN_SUCCESS = "CLEAR_LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CLEAR_CREATE_PLAN_SUCCESS = "CLEAR_CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAIL = "CREATE_PLAN_FAIL";
export const CREATE_PLAN_REQUEST = "CREATE_PLAN_REQUEST";
export const CREATE_PLAN_LOADING = "CREATE_PLAN_LOADING";

export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAIL = "GET_PLANS_FAIL";
export const GET_PLANS_REQUEST = "GET_PLANS_REQUEST";
export const GET_PLANS_LOADING = "GET_PLANS_LOADING";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_LOADING = "GET_USERS_LOADING";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_LOADING = "GET_USER_LOADING";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CLEAR_UPDATE_USER_SUCCESS = "CLEAR_UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";

export const GET_USER_INVESTMENTS_SUCCESS = "GET_USER_INVESTMENTS_SUCCESS";
export const GET_USER_INVESTMENTS_FAIL = "GET_USER_INVESTMENTS_FAIL";
export const GET_USER_INVESTMENTS_REQUEST = "GET_USER_INVESTMENTS_REQUEST";
export const GET_USER_INVESTMENTS_LOADING = "GET_USER_INVESTMENTS_LOADING";

export const GET_INVESTMENT_SUCCESS = "GET_INVESTMENT_SUCCESS";
export const GET_INVESTMENT_FAIL = "GET_INVESTMENT_FAIL";
export const GET_INVESTMENT_REQUEST = "GET_INVESTMENT_REQUEST";
export const GET_INVESTMENT_LOADING = "GET_INVESTMENT_LOADING";

export const CREATE_INVESTMENT_SUCCESS = "CREATE_INVESTMENT_SUCCESS";
export const CLEAR_CREATE_INVESTMENT_SUCCESS =
  "CLEAR_CREATE_INVESTMENT_SUCCESS";
export const CREATE_INVESTMENT_FAIL = "CREATE_INVESTMENT_FAIL";
export const CREATE_INVESTMENT_REQUEST = "CREATE_INVESTMENT_REQUEST";
export const CREATE_INVESTMENT_LOADING = "CREATE_INVESTMENT_LOADING";

export const UPDATE_INVESTMENT_SUCCESS = "UPDATE_INVESTMENT_SUCCESS";
export const CLEAR_UPDATE_INVESTMENT_SUCCESS =
  "CLEAR_UPDATE_INVESTMENT_SUCCESS";
export const UPDATE_INVESTMENT_FAIL = "UPDATE_INVESTMENT_FAIL";
export const UPDATE_INVESTMENT_REQUEST = "UPDATE_INVESTMENT_REQUEST";
export const UPDATE_INVESTMENT_LOADING = "UPDATE_INVESTMENT_LOADING";

export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CLEAR_CREATE_PAYMENT_SUCCESS = "CLEAR_CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL";
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_LOADING = "CREATE_PAYMENT_LOADING";

export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAIL = "GET_PAYMENTS_FAIL";
export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST";
export const GET_PAYMENTS_LOADING = "GET_PAYMENTS_LOADING";

export const CREATE_WITHDRAW_SUCCESS = "CREATE_WITHDRAW_SUCCESS";
export const CLEAR_CREATE_WITHDRAW_SUCCESS = "CLEAR_CREATE_WITHDRAW_SUCCESS";
export const CREATE_WITHDRAW_FAIL = "CREATE_WITHDRAW_FAIL";
export const CREATE_WITHDRAW_REQUEST = "CREATE_WITHDRAW_REQUEST";
export const CREATE_WITHDRAW_LOADING = "CREATE_WITHDRAW_LOADING";

export const UPDATE_WITHDRAW_SUCCESS = "UPDATE_WITHDRAW_SUCCESS";
export const CLEAR_UPDATE_WITHDRAW_SUCCESS = "CLEAR_UPDATE_WITHDRAW_SUCCESS";
export const UPDATE_WITHDRAW_FAIL = "UPDATE_WITHDRAW_FAIL";
export const UPDATE_WITHDRAW_REQUEST = "UPDATE_WITHDRAW_REQUEST";
export const UPDATE_WITHDRAW_LOADING = "UPDATE_WITHDRAW_LOADING";

export const GET_WITHDRAWS_SUCCESS = "GET_WITHDRAWS_SUCCESS";
export const GET_WITHDRAWS_FAIL = "GET_WITHDRAWS_FAIL";
export const GET_WITHDRAWS_REQUEST = "GET_WITHDRAWS_REQUEST";
export const GET_WITHDRAWS_LOADING = "GET_WITHDRAWS_LOADING";

export const GET_USER_WITHDRAWS_SUCCESS = "GET_USER_WITHDRAWS_SUCCESS";
export const GET_USER_WITHDRAWS_FAIL = "GET_USER_WITHDRAWS_FAIL";
export const GET_USER_WITHDRAWS_REQUEST = "GET_USER_WITHDRAWS_REQUEST";
export const GET_USER_WITHDRAWS_LOADING = "GET_USER_WITHDRAWS_LOADING";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const CLEAR_UPDATE_PASSWORD_SUCCESS = "CLEAR_UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_LOADING = "UPDATE_PASSWORD_LOADING";

export const GET_LOGINUSER_SUCCESS = "GET_LOGINUSER_SUCCESS";
export const CLEAR_GET_LOGINUSER_SUCCESS = "CLEAR_GET_LOGINUSER_SUCCESS";
export const GET_LOGINUSER_FAIL = "GET_LOGINUSER_FAIL";
export const GET_LOGINUSER_REQUEST = "GET_LOGINUSER_REQUEST";
export const GET_LOGINUSER_LOADING = "GET_LOGINUSER_LOADING";

export const GET_RESETLINK_SUCCESS = "GET_RESETLINK_SUCCESS";
export const CLEAR_GET_RESETLINK_SUCCESS = "CLEAR_GET_RESETLINK_SUCCESS";
export const GET_RESETLINK_FAIL = "GET_RESETLINK_FAIL";
export const GET_RESETLINK_REQUEST = "GET_RESETLINK_REQUEST";
export const GET_RESETLINK_LOADING = "GET_RESETLINK_LOADING";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CLEAR_RESET_PASSWORD_SUCCESS = "CLEAR_RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";

export const CREATE_MAIL_SUCCESS = "CREATE_MAIL_SUCCESS";
export const CLEAR_CREATE_MAIL_SUCCESS = "CLEAR_CREATE_MAIL_SUCCESS";
export const CREATE_MAIL_FAIL = "CREATE_MAIL_FAIL";
export const CREATE_MAIL_REQUEST = "CREATE_MAIL_REQUEST";
export const CREATE_MAIL_LOADING = "CREATE_MAIL_LOADING";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const CLEAR_DELETE_USER_SUCCESS = "CLEAR_DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";

export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAIL = "GET_REFERRAL_FAIL";
export const GET_REFERRAL_REQUEST = "GET_REFERRAL_REQUEST";
export const GET_REFERRAL_LOADING = "GET_REFERRAL_LOADING";

export const GET_REFERERS_SUCCESS = "GET_REFERERS_SUCCESS";
export const GET_REFERERS_FAIL = "GET_REFERERS_FAIL";
export const GET_REFERERS_REQUEST = "GET_REFERERS_REQUEST";
export const GET_REFERERS_LOADING = "GET_REFERERS_LOADING";
