import React from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { FormButtonPrimary } from "../../../components/buttons";
import { ProfileForm } from "../../../components/Forms/ProfileForm";
import { UpdatePasswordForm } from "../../../components/Forms/UpdatePasswordForm";

const Settings = () => {
  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Update Account"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />

        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[25%]">
          <ProfileForm />
          <UpdatePasswordForm />
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Settings;
