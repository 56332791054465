import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { FormButtonPrimary } from "../../../components/buttons";
import {
  clearUpdateInvestmentsSuccess,
  getUsersRequest,
  updateInvestmentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UserCard } from "../../../components/cards/userCard";
import { AdminDashboardNav } from "../../../components/Nav/adminDashboardNav";
import { CircularProgress } from "@material-ui/core";

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    users,
    user,
    getUsersError,
    investments,
    getUsersLoading,
    token,
    updateInvestmentsLoading,
    updateInvestmentsError,
    updateInvestmentsSuccess,
  } = useSelector((state) => {
    const {
      success: {
        getUsers: getUsersSuccess,
        updateInvestments: updateInvestmentsSuccess,
      },
      errors: {
        getUsers: getUsersError,
        updateInvestments: updateInvestmentsError,
      },
    } = state.ajaxStatuses;

    const { getUsersLoading, updateInvestmentsLoading } =
      state.loadingIndicator;

    const { user, users, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      users,
      getUsersError,
      investments,
      updateInvestmentsLoading,
      updateInvestmentsError,
      updateInvestmentsSuccess,
      getUsersLoading,
      token,
      user,
    };
  });

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [getUsersRequest]);

  useEffect(() => {
    if (getUsersError) {
      toast.error(getUsersError, {
        duration: 3000,
      });
    }
  }, [getUsersError]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Users"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <AdminDashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          {getUsersLoading ? (
            <CircularProgress size={20} className="my-10 mx-auto text-center" />
          ) : (
            <div className="mt-10">
              {users.map((user, index) => (
                <UserCard user={user} key={index} singlePage={true} />
              ))}
            </div>
          )}
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Dashboard;
