import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { Link } from "react-router-dom";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { AccountDetailsCard } from "../../../components/cards/accountDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getLogUserRequest,
  getPlansRequest,
  getUserInvestmentsRequest,
  getUserWithdrawsRequest,
} from "../../../redux/action";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { CircularProgress } from "@material-ui/core";

const Home = () => {
  const [activeInvestment, setActiveInvestment] = useState(0);
  const [earnedTotal, setEarnedTotal] = useState(0);

  const dispatch = useDispatch();

  const {
    getPlansSuccess,
    getPlansError,
    getPlansLoading,
    user,
    plans,
    getUserInvestmentsError,
    getLogUserError,
    investments,
    getUserInvestmentsLoading,
    token,
    getLogUserLoading,
    getUserWithdrawsError,
    getUserWithdrawsLoading,
    userWithdrawals,
  } = useSelector((state) => {
    const {
      success: {
        getPlans: getPlansSuccess,
        getUserInvestments: getUserInvestmentsSuccess,
        getUserWithdraws: getUserWithdrawsSuccess,
      },
      errors: {
        getPlans: getPlansError,
        getUserInvestments: getUserInvestmentsError,
        getLogUser: getLogUserError,
        getUserWithdraws: getUserWithdrawsError,
      },
    } = state.ajaxStatuses;

    const {
      getPlansLoading,
      getUserInvestmentsLoading,
      getLogUserLoading,
      getUserWithdrawsLoading,
    } = state.loadingIndicator;

    const { user, token } = state.userData;
    const { plans } = state.planData;
    const { investments } = state.investmentData;

    const { userWithdrawals } = state.withdrawData;

    return {
      getPlansSuccess,
      getPlansError,
      getPlansLoading,
      user,
      plans,
      getUserInvestmentsError,
      getLogUserError,
      investments,
      getUserInvestmentsLoading,
      token,
      getLogUserLoading,
      getUserWithdrawsSuccess,
      getUserWithdrawsError,
      getUserWithdrawsLoading,
      userWithdrawals,
    };
  });

  useEffect(() => {
    if (getUserWithdrawsError) {
      toast.error(getUserWithdrawsError, {
        duration: 3000,
      });
    }
  }, [getUserWithdrawsError]);

  useEffect(() => {
    if (getLogUserError) {
      toast.error(getLogUserError, {
        duration: 3000,
      });
    }
  }, [getLogUserError]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  useEffect(() => {
    // dispatch(getPlansRequest());
    dispatch(getUserInvestmentsRequest(user._id));
    dispatch(getLogUserRequest(token));
    dispatch(getUserWithdrawsRequest(user._id));
  }, [getUserInvestmentsRequest, getUserWithdrawsRequest]);

  const setAccountCards = () => {
    let activeInvest = 0;
    let earnedTotal = 0;

    investments.map((investment) => {
      if (investment.status === "Active") {
        activeInvest += investment.amount;
      }
      if (investment.status === "Completed") {
        earnedTotal += investment.expectedReturn;
      }
    });
    setActiveInvestment(activeInvest);
    setEarnedTotal(earnedTotal);
  };

  const [pendingWithdraw, setPendingWithdraw] = useState(0);
  const [completedWithdraw, setCompletedWithdraw] = useState(0);

  const setWithdraw = () => {
    let withAmount = 0;
    let completedWithdrawal = 0;

    userWithdrawals.map((data) => {
      if (data.status === "Pending") {
        withAmount += data.amount;
      }

      if (data.status === "Completed") {
        completedWithdrawal += data.amount;
      }
    });

    setPendingWithdraw(withAmount);
    setCompletedWithdraw(completedWithdrawal);
  };

  useEffect(() => {
    if (!getUserInvestmentsLoading) {
      setAccountCards();
    }
    if (!getUserWithdrawsLoading) {
      setWithdraw();
    }
  }, [setAccountCards, setWithdraw]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Account"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-body text-xl md:text-2xl text-center">
            Your account:
          </h3>

          <AccountDetailsCard
            head={"User:"}
            content={`${user.firstName} ${" "} ${user.lastName}`}
            margin={"mt-6"}
            border={"border-y"}
          />
          <AccountDetailsCard
            head={"Registration Date:"}
            content={
              user.createdAt && format(Date.parse(user.createdAt), "MM/dd/yyyy")
            }
            margin={"mt-0"}
            border={"border-b"}
          />
          <AccountDetailsCard
            head={"Last Access:"}
            content={
              user.lastAccess &&
              format(Date.parse(user.lastAccess), "MM/dd/yyyy")
            }
            margin={"mt-0"}
            border={"border-b"}
          />

          <div>
            {getUserInvestmentsLoading ? (
              <div className="my-32 flex items-center justify-items-center">
                <CircularProgress size={20} className="mx-auto" />
              </div>
            ) : (
              <>
                <AccountDetailsCard
                  head={"Account Balance:"}
                  content={`$${
                    user.bitcoinBalance +
                    user.ethereumBalance +
                    user.usdtBalance
                  }`}
                  margin={"mt-16"}
                  border={"border-y"}
                />
                <AccountDetailsCard
                  head={"Earned Total:"}
                  content={`$${earnedTotal}`}
                  margin={"mt-0"}
                  border={"border-b"}
                />
                <AccountDetailsCard
                  head={"Pending Withdrawal:"}
                  content={`$${pendingWithdraw}`}
                  margin={"mt-0"}
                  border={"border-b"}
                />
                <AccountDetailsCard
                  head={"Withdrew Total:"}
                  content={`$${completedWithdraw}`}
                  margin={"mt-0"}
                  border={"border-b"}
                />
                <AccountDetailsCard
                  head={"Active Deposit:"}
                  content={`$${activeInvestment}`}
                  margin={"mt-0"}
                  border={"border-b"}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Home;
