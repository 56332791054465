import React from "react";

export const AccountDetailsCard = ({ head, content, margin, border }) => {
  return (
    <div
      className={`grid grid-cols-2 text-sm md:text-base justify-items-center ${border} border-white text-white font-body py-3  ${margin}`}
    >
      <h5>{head}</h5>
      <h5>{content}</h5>
    </div>
  );
};
