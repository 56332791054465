import React, { useState, useEffect } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { AdminDashboardNav } from "../../../components/Nav/adminDashboardNav";
import AdminWithdrawalForm from "../../../components/Forms/adminWithdrawalFrom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { getWithdrawsRequest } from "../../../redux/action";

const Withdrawals = () => {
  const dispatch = useDispatch();

  const {
    getWithdrawsSuccess,
    getWithdrawsError,
    getWithdrawsLoading,
    withdraws,
  } = useSelector((state) => {
    const {
      success: { getWithdraws: getWithdrawsSuccess },
      errors: { getWithdraws: getWithdrawsError },
    } = state.ajaxStatuses;

    const { getWithdrawsLoading } = state.loadingIndicator;

    const { withdraws } = state.withdrawData;

    return {
      getWithdrawsSuccess,
      getWithdrawsError,
      getWithdrawsLoading,
      withdraws,
    };
  });

  useEffect(() => {
    if (getWithdrawsError) {
      toast.error(getWithdrawsError, {
        duration: 3000,
      });
    }
  }, [getWithdrawsError]);

  useEffect(() => {
    dispatch(getWithdrawsRequest());
  }, [getWithdrawsRequest]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Withdrawals"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <AdminDashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          {getWithdrawsLoading ? (
            <CircularProgress size={20} className="my-10 mx-auto text-center" />
          ) : (
            <>
              {withdraws.map((withdraw, index) => (
                <AdminWithdrawalForm data={withdraw} />
              ))}
            </>
          )}
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Withdrawals;
