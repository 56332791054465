import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FormButtonPrimary } from "../buttons";
import {
  clearUpdateUserSuccess,
  getLogUserRequest,
  updateUserRequest,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormError } from "../FormError";

export const ProfileForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, updateUserError, updateUserSuccess, updateUserLoading, token } =
    useSelector((state) => {
      const {
        success: { updateUser: updateUserSuccess },
        errors: { updateUser: updateUserError },
      } = state.ajaxStatuses;

      const { updateUserLoading } = state.loadingIndicator;

      const { user, token } = state.userData;

      return {
        user,
        updateUserError,
        updateUserSuccess,
        updateUserLoading,
        token,
      };
    });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      bitcoinAddress: "",
      ethereumAddress: "",
      usdtAddress: "",
      phone: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required("first name is required")
        .max(32, "first name is too long")
        .trim()
        .required("Username is required"),
      lastName: yup
        .string()
        .required("last name is required")
        .max(32, "last name is too long")
        .trim()
        .required("last name is required"),
      phone: yup.string().required("Phone is required"),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      const id = user._id;
      console.log(prop);

      dispatch(updateUserRequest({ formData, id }));
    },
  });

  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toast.success(updateUserSuccess, {
        duration: 3000,
      });

      dispatch(clearUpdateUserSuccess());
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    dispatch(getLogUserRequest(token));
  }, [getLogUserRequest]);

  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        firstName: user.firstName,
        lastName: user.lastName,
        bitcoinAddress: user.bitcoinAddress,
        ethereumAddress: user.ethereumAddress,
        usdtAddress: user.usdtAddress,
        phone: user.phone,
      }));
    }
  }, [user]);

  return (
    <div>
      <h3 className="text-white font-body text-2xl text-center">
        Your account:
      </h3>

      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-y  border-white text-white font-body py-3  mt-6`}
      >
        <h5>First name: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("firstName")}
        />
        {formik.errors.firstName && formik.touched.firstName && (
          <FormError message={formik.errors.firstName} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>Last name: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("lastName")}
        />
        {formik.errors.lastName && formik.touched.lastName && (
          <FormError message={formik.errors.lastName} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>Phone: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("phone")}
        />
        {formik.errors.phone && formik.touched.phone && (
          <FormError message={formik.errors.phone} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>Bitcoin address: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("bitcoinAddress")}
        />
        {formik.errors.bitcoinAddress && formik.touched.bitcoinAddress && (
          <FormError message={formik.errors.bitcoinAddress} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>Ethereum address: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("ethereumAddress")}
        />
        {formik.errors.ethereumAddress && formik.touched.ethereumAddress && (
          <FormError message={formik.errors.ethereumAddress} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>USDT(TRC20) address: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("usdtAddress")}
        />
        {formik.errors.usdtAddress && formik.touched.usdtAddress && (
          <FormError message={formik.errors.usdtAddress} />
        )}
      </div>

      <div className="w-24 mt-6 flex justify-center mx-auto">
        <FormButtonPrimary
          text={"Update"}
          action={formik.handleSubmit}
          loading={updateUserLoading}
          disabled={!formik.isValid}
        />
      </div>
    </div>
  );
};
