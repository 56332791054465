import { BrowserRouter as Router } from "react-router-dom";
import Pages from "./pages";
import { toast, ToastContainer } from "react-toastify";
import { IoLogoWhatsapp } from "react-icons/io";

//css
import "react-toastify/dist/ReactToastify.css";
import "./dot.css";

//hooks
import ScrollToTheTop from "./hooks/ScrollToTheTop";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect } from "react";

function App() {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute("src", "/js/translate.js");
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <div id="google_translate_element"></div> */}
        <Router>
          <ScrollToTheTop />
          <>
            <ToastContainer
              position="top-center"
              theme="dark"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>

          <a
            href="https://wa.me/qr/BAJYNTMFE36RD1"
            target="_blank"
            className="fixed z-50 bg-green-500 bottom-4 left-4 text-black flex items-center justify-center rounded-full p-2 shadow-lg"
          >
            <div className="relative">
              <span class="animate-pingslow absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75 duration-1000"></span>
              <IoLogoWhatsapp className="text-4xl relative text-white" />
            </div>
          </a>
          <Pages />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
