import React from "react";
import { Switch, Route } from "react-router-dom";
import { AdminPrivateRoute } from "../../components/AdminPrivateRoute";
import EditUser from "./EditUser";
import Home from "./Home";
import Dashboard from "./Dashboard";
import UserInvestment from "./UserInvestments";
import UserInvestments from "./UserInvestments";
import Withdrawals from "./Withdrawals";
// import Request from "./Requests";
// import UserInvestments from "./UserInvestment";
// import Mail from "./Mail";

// import SendMail from "./SendMail";

const Admin = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={path}>
        <Home />
      </Route>
      <AdminPrivateRoute path={`${path}/dashboard`} component={Dashboard} />
      <AdminPrivateRoute exact path={`${path}/user/:id`} component={EditUser} />
      <AdminPrivateRoute
        path={`${path}/investments/:id`}
        component={UserInvestments}
      ></AdminPrivateRoute>
      <AdminPrivateRoute
        path={`${path}/withdrawals`}
        component={Withdrawals}
      ></AdminPrivateRoute>
      {/*  <AdminPrivateRoute path={`${path}/mail`} component={Mail} /> */}
    </Switch>
  );
};

export default Admin;
