import React from "react";
import { BsFillPersonFill } from "react-icons/bs";

export const WorkCard = ({ Icon, number, text }) => {
  return (
    <div className="text-center flex flex-col items-center justify-center gap-y-5">
      <div className="border-2 w-[110px] h-[110px] flex items-center justify-center border-primary p-6 rounded-full bg-black relative  icon-box-shadow">
        <Icon className="text-5xl text-primary" />
        <div className="border-2 border-primary font-light h-8 w-8 flex items-center justify-center rounded-full text-primary bg-black absolute -top-1 -right-1">
          {number}
        </div>
      </div>
      <h5 className="text-primary text-xl font-head text-center">{text}</h5>
    </div>
  );
};
