import { CREATE_PAYMENT_SUCCESS, GET_PAYMENTS_SUCCESS } from "../types";

const initialState = () => ({
  payment: {},
  payments: [],
});

const paymentReducer = (state = initialState(), { type, payload }) => {
  switch (type) {
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: payload.payment,
      };
    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: payload.payments,
      };

    default:
      return state;
  }
};

export default paymentReducer;
