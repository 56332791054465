import {
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_LOADING,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_LOADING,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_LOADING,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_LOADING,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_LOADING,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_LOADING,
  CLEAR_UPDATE_USER_SUCCESS,
  CLEAR_UPDATE_PASSWORD_SUCCESS,
  CLEAR_REGISTER_SUCCESS,
  CLEAR_LOGIN_SUCCESS,
  GET_LOGINUSER_REQUEST,
  GET_LOGINUSER_SUCCESS,
  GET_LOGINUSER_FAIL,
  GET_LOGINUSER_LOADING,
  GET_RESETLINK_REQUEST,
  GET_RESETLINK_SUCCESS,
  CLEAR_GET_RESETLINK_SUCCESS,
  GET_RESETLINK_FAIL,
  GET_RESETLINK_LOADING,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  CLEAR_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  CREATE_MAIL_REQUEST,
  CREATE_MAIL_SUCCESS,
  CLEAR_CREATE_MAIL_SUCCESS,
  CREATE_MAIL_FAIL,
  CREATE_MAIL_LOADING,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  CLEAR_DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_LOADING,
  GET_REFERRAL_REQUEST,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAIL,
  GET_REFERRAL_LOADING,
  GET_REFERERS_REQUEST,
  GET_REFERERS_SUCCESS,
  GET_REFERERS_FAIL,
  GET_REFERERS_LOADING,
} from "../types";

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const registerRequest = (prop) => {
  return {
    type: REGISTER_REQUEST,
    payload: prop,
  };
};

export const registerSuccess = (prop) => {
  return {
    type: REGISTER_SUCCESS,
    payload: prop,
  };
};
export const clearRegisterSuccess = (prop) => {
  return {
    type: CLEAR_REGISTER_SUCCESS,
    payload: prop,
  };
};

export const registerFailure = (errors) => {
  return {
    type: REGISTER_FAIL,
    payload: { errors },
  };
};

export const registerLoading = (loading) => {
  return {
    type: REGISTER_LOADING,
    payload: loading,
  };
};

export const loginRequest = (prop) => {
  return {
    type: LOGIN_REQUEST,
    payload: prop,
  };
};

export const loginSuccess = (prop) => {
  return {
    type: LOGIN_SUCCESS,
    payload: prop,
  };
};

export const clearLoginSuccess = (prop) => {
  return {
    type: CLEAR_LOGIN_SUCCESS,
    payload: prop,
  };
};

export const loginFailure = (errors) => {
  return {
    type: LOGIN_FAIL,
    payload: { errors },
  };
};

export const loginLoading = (loading) => {
  return {
    type: LOGIN_LOADING,
    payload: loading,
  };
};

export const getUsersRequest = (prop) => {
  return {
    type: GET_USERS_REQUEST,
    payload: prop,
  };
};

export const getUsersSuccess = (prop) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: prop,
  };
};

export const getUsersFailure = (errors) => {
  return {
    type: GET_USERS_FAIL,
    payload: { errors },
  };
};

export const getUsersLoading = (loading) => {
  return {
    type: GET_USERS_LOADING,
    payload: loading,
  };
};

export const getUserRequest = (prop) => {
  return {
    type: GET_USER_REQUEST,
    payload: prop,
  };
};

export const getUserSuccess = (prop) => {
  return {
    type: GET_USER_SUCCESS,
    payload: prop,
  };
};

export const getUserFailure = (errors) => {
  return {
    type: GET_USER_FAIL,
    payload: { errors },
  };
};

export const getUserLoading = (loading) => {
  return {
    type: GET_USER_LOADING,
    payload: loading,
  };
};

export const updateUserRequest = (prop) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: prop,
  };
};

export const updateUserSuccess = (prop) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: prop,
  };
};

export const clearUpdateUserSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_USER_SUCCESS,
    payload: prop,
  };
};

export const updateUserFailure = (errors) => {
  return {
    type: UPDATE_USER_FAIL,
    payload: { errors },
  };
};

export const updateUserLoading = (loading) => {
  return {
    type: UPDATE_USER_LOADING,
    payload: loading,
  };
};

export const updatePasswordRequest = (prop) => {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    payload: prop,
  };
};

export const updatePasswordSuccess = (prop) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: prop,
  };
};

export const clearUpdatePasswordSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_PASSWORD_SUCCESS,
    payload: prop,
  };
};

export const updatePasswordFailure = (errors) => {
  return {
    type: UPDATE_PASSWORD_FAIL,
    payload: { errors },
  };
};

export const updatePasswordLoading = (loading) => {
  return {
    type: UPDATE_PASSWORD_LOADING,
    payload: loading,
  };
};

export const getLogUserRequest = (prop) => {
  return {
    type: GET_LOGINUSER_REQUEST,
    payload: prop,
  };
};

export const getLogUserSuccess = (prop) => {
  return {
    type: GET_LOGINUSER_SUCCESS,
    payload: prop,
  };
};

export const getLogUserFailure = (errors) => {
  return {
    type: GET_LOGINUSER_FAIL,
    payload: { errors },
  };
};

export const getLogUserLoading = (loading) => {
  return {
    type: GET_LOGINUSER_LOADING,
    payload: loading,
  };
};

export const getResetLinkRequest = (prop) => {
  return {
    type: GET_RESETLINK_REQUEST,
    payload: prop,
  };
};

export const getResetLinkSuccess = (prop) => {
  return {
    type: GET_RESETLINK_SUCCESS,
    payload: prop,
  };
};

export const clearGetResetLinkSuccess = (prop) => {
  return {
    type: CLEAR_GET_RESETLINK_SUCCESS,
    payload: prop,
  };
};

export const getResetLinkFailure = (errors) => {
  return {
    type: GET_RESETLINK_FAIL,
    payload: { errors },
  };
};

export const getResetLinkLoading = (loading) => {
  return {
    type: GET_RESETLINK_LOADING,
    payload: loading,
  };
};

export const resetPasswordRequest = (prop) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: prop,
  };
};

export const resetPasswordSuccess = (prop) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: prop,
  };
};

export const clearResetPasswordSuccess = (prop) => {
  return {
    type: CLEAR_RESET_PASSWORD_SUCCESS,
    payload: prop,
  };
};

export const resetPasswordFailure = (errors) => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: { errors },
  };
};

export const resetPasswordLoading = (loading) => {
  return {
    type: RESET_PASSWORD_LOADING,
    payload: loading,
  };
};

export const createMailRequest = (prop) => {
  return {
    type: CREATE_MAIL_REQUEST,
    payload: prop,
  };
};

export const createMailSuccess = (prop) => {
  return {
    type: CREATE_MAIL_SUCCESS,
    payload: prop,
  };
};

export const clearCreateMailSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_MAIL_SUCCESS,
    payload: prop,
  };
};

export const createMailFailure = (errors) => {
  return {
    type: CREATE_MAIL_FAIL,
    payload: { errors },
  };
};

export const createMailLoading = (loading) => {
  return {
    type: CREATE_MAIL_LOADING,
    payload: loading,
  };
};

export const deleteUserRequest = (prop) => {
  return {
    type: DELETE_USER_REQUEST,
    payload: prop,
  };
};

export const deleteUserSuccess = (prop) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: prop,
  };
};

export const clearDeleteUserSuccess = (prop) => {
  return {
    type: CLEAR_DELETE_USER_SUCCESS,
    payload: prop,
  };
};

export const deleteUserFailure = (errors) => {
  return {
    type: DELETE_USER_FAIL,
    payload: { errors },
  };
};

export const deleteUserLoading = (loading) => {
  return {
    type: DELETE_USER_LOADING,
    payload: loading,
  };
};

export const getReferralRequest = (prop) => {
  return {
    type: GET_REFERRAL_REQUEST,
    payload: prop,
  };
};

export const getReferralSuccess = (prop) => {
  return {
    type: GET_REFERRAL_SUCCESS,
    payload: prop,
  };
};

export const getReferralFailure = (errors) => {
  return {
    type: GET_REFERRAL_FAIL,
    payload: { errors },
  };
};

export const getReferralLoading = (loading) => {
  return {
    type: GET_REFERRAL_LOADING,
    payload: loading,
  };
};

export const getReferersRequest = (prop) => {
  return {
    type: GET_REFERERS_REQUEST,
    payload: prop,
  };
};

export const getReferersSuccess = (prop) => {
  return {
    type: GET_REFERERS_SUCCESS,
    payload: prop,
  };
};

export const getReferersFailure = (errors) => {
  return {
    type: GET_REFERERS_FAIL,
    payload: { errors },
  };
};

export const getReferersLoading = (loading) => {
  return {
    type: GET_REFERERS_LOADING,
    payload: loading,
  };
};
