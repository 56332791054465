import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { CREATE_PAYMENT_REQUEST, GET_PAYMENTS_REQUEST } from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  createPaymentFailure,
  createPaymentLoading,
  createPaymentSuccess,
  getPaymentsFailure,
  getPaymentsLoading,
  getPaymentsSuccess,
} from "../action";

const ajaxDBCalls = {
  createPayment: async (formData) => {
    const response = await axios.post(`/payment/create`, formData);
    return response;
  },
  getPayments: async () => {
    const response = await axios.get(`/payment`);
    return response;
  },
};

function* createPayment({ payload }) {
  try {
    yield put(createPaymentLoading(true));

    const res = yield call(ajaxDBCalls.createPayment, payload);

    yield put(createPaymentSuccess(res.data));

    yield put(createPaymentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createPaymentFailure(errorMessage));
    yield put(createPaymentLoading(false));
    yield delay();
    yield put(createPaymentFailure(""));
  }
}
function* getPayments({ payload }) {
  try {
    yield put(getPaymentsLoading(true));

    const res = yield call(ajaxDBCalls.getPayments, payload);

    yield put(getPaymentsSuccess(res.data));

    yield put(getPaymentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getPaymentsFailure(errorMessage));
    yield put(getPaymentsLoading(false));
    yield delay();
    yield put(getPaymentsFailure(""));
  }
}

//Watchers
function* createPaymentWatcher() {
  yield takeLatest(CREATE_PAYMENT_REQUEST, createPayment);
}
function* getPaymentsWatcher() {
  yield takeLatest(GET_PAYMENTS_REQUEST, getPayments);
}

export default function* paymentSagas() {
  yield spawn(createPaymentWatcher);
  yield spawn(getPaymentsWatcher);
}
