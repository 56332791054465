import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { FaTimes } from "react-icons/fa";
import { format } from "date-fns";
import { EditInvestmentForm } from "./editInvestmentForm";
import { EditWithdrawalForm } from "./editWithdrawalFrom";

const AdminWithdrawalForm = ({ data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="h-screen w-screen flex justify-center outline-0 "
      >
        <div className=" h-full w-full  rounded  bg-dark outline-0 relative transition duration-500">
          <FaTimes
            onClick={handleClose}
            className="cursor-pointer text-white  text-2xl absolute right-5 top-5"
          />

          <EditWithdrawalForm data={data} handleClose={handleClose} />
        </div>
      </Modal>

      <div className="">
        <div
          className={`relative flex justify-between md:grid md:grid-cols-4 justify-items-center items-center text-xs md:text-base border-b   border-white text-white font-body py-3 `}
        >
          <div>
            <h5>{data.user.email && data.user.email}</h5>
            <h5 className="mt-2">
              {data.createdAt &&
                format(Date.parse(data.createdAt), "MM/dd/yyyy")}
            </h5>
          </div>

          <div>
            <h5>${data.amount && data.amount}</h5>
            <h5 className="mt-2">{data.status && data.status}</h5>
          </div>

          <div>
            <h5>{data.chain && data.chain}</h5>
            <div
              onClick={handleOpen}
              className=" w-16 bg-primary mt-2 text-black flex items-center justify-center text-xs h-6 cursor-pointer rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              Edit
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminWithdrawalForm;
