import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { DashboardPlanCard } from "../../../components/cards/dashboardPlanCard";
import { FormButtonPrimary } from "../../../components/buttons";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AiOutlineCopy } from "react-icons/ai";
import {
  clearCreateInvestmentSuccess,
  createInvestmentRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";

const Deposit = () => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(true);
  const [chainAddress, setChainAddress] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const { location, goBack, push } = useHistory();

  const copyToClipboard = (value) => {
    console.log(location.state);

    navigator.clipboard.writeText(value);

    toast.success("Copied", {
      duration: 3000,
    });
  };

  // const [location.state, setlocation.state] = useState(null);

  useEffect(() => {
    if (location.state.chain) {
      if (location.state.chain === "Bitcoin") {
        setChainAddress("1PeSfaW5yXE6zZFjTe5p9NXuYymHgDXc64");
      }
      if (location.state.chain === "Ethereum") {
        setChainAddress("0x1a0e51ca31fce1b60988b8c728ad478cee70d3f8");
      }
      if (location.state.chain === "USDT(TRC20)") {
        setChainAddress("TLK9BzkU4fcBpKGC3ioi3uunaG4Gc82p8A");
      }

      // setlocation.state(location.state);
    }
  }, [location]);

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
    claimInvestmentSuccess,
    claimInvestmentError,
    claimInvestmentLoading,
    createInvestmentSuccess,
    createInvestmentError,
    createInvestmentLoading,
    token,
  } = useSelector((state) => {
    const {
      success: {
        claimInvestment: claimInvestmentSuccess,
        createInvestment: createInvestmentSuccess,
      },
      errors: {
        getUserInvestments: getUserInvestmentsError,
        claimInvestment: claimInvestmentError,
        createInvestment: createInvestmentError,
      },
    } = state.ajaxStatuses;

    const {
      getUserInvestmentsLoading,
      claimInvestmentLoading,
      createInvestmentLoading,
    } = state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      claimInvestmentSuccess,
      claimInvestmentError,
      claimInvestmentLoading,
      createInvestmentSuccess,
      createInvestmentError,
      createInvestmentLoading,
      token,
    };
  });

  useEffect(() => {
    if (createInvestmentError) {
      toast.error(createInvestmentError, {
        duration: 3000,
      });
    }
  }, [createInvestmentError]);

  useEffect(() => {
    if (createInvestmentSuccess) {
      toast.success(createInvestmentSuccess, {
        duration: 3000,
      });

      push("/dashboard/home");

      dispatch(clearCreateInvestmentSuccess());
    }
  }, [createInvestmentSuccess]);

  const createInvestment = () => {
    let formdata = {};

    if (transactionId !== "") {
      formdata.user = user._id;
      formdata.firstName = user.firstName;
      formdata.email = user.email;
      formdata.amount = location.state.amount;
      formdata.chain = location.state.chain;
      formdata.expectedReturn = location.state.payout;
      formdata.profitProduction = location.state.percent;
      formdata.planName = location.state.newPlan;
      formdata.transactionId = transactionId;
      formdata.reinvest = false;

      dispatch(createInvestmentRequest(formdata));
    }
  };

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Deposit"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-head text-xl md:text-2xl text-center">
            Please confirm your deposit:
          </h3>

          <div className="mt-10">
            <div className="my-10">
              <h6 className="text-white text-sm md:text-lg font-body text-center mb-5">
                Kindly copy the {location.state.chain} wallet address to make
                your payment.
              </h6>

              <div className="text-center items-center justify-center`">
                <h6 className="text-white text-xs md:text-lg font-body text-center">
                  {chainAddress}
                </h6>
                <div className="w-28 md:w-40 mt-3  flex justify-center mx-auto">
                  <div
                    className=" text-xs underline text-primary flex items-center"
                    onClick={() => copyToClipboard(chainAddress)}
                    text={"Copy Address"}
                  >
                    <AiOutlineCopy />
                    Copy Address
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base border-y  border-white text-white font-body py-3 `}
            >
              <h5>Plan:</h5>
              <h5>{location.state.newPlan}</h5>
            </div>
            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
            >
              <h5>Profit:</h5>
              <h5>{location.state.percent}% Daily for 1 day</h5>
            </div>
            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
            >
              <h5>Principal Return:</h5>
              <h5>Yes</h5>
            </div>
            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
            >
              <h5>Credit Amount:</h5>
              <h5>${location.state.amount}.00</h5>
            </div>
            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
            >
              <h5>Deposit Fee:</h5>
              <h5>0.00% + $0.00 (min. $0.00 max. $0.00)</h5>
            </div>
            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base   border-white text-white font-body py-3 `}
            >
              <h5>Debit Amount:</h5>
              <h5>${location.state.amount}.00</h5>
            </div>

            <div className="mt-10">
              <div
                className={`text-center justify-items-center text-xs md:text-base border-y  border-white text-white font-body py-3 `}
              >
                <h5>Required Information</h5>
              </div>
              <div
                className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
              >
                <h5>Transaction ID</h5>
                <input
                  type="text"
                  className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Enter transaction Id"
                  onChange={(e) => {
                    setTransactionId(e.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div className="flex w-52 mx-auto items-center justify-items-center gap-x-5">
              <div className="w-24 mt-6 flex justify-center mx-auto">
                <FormButtonPrimary action={() => goBack()} text={"Cancel"} />
              </div>
              <div className="w-24 mt-6 flex justify-center mx-auto">
                <FormButtonPrimary
                  action={() => createInvestment()}
                  loading={createInvestmentLoading}
                  text={"Proceed"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Deposit;
