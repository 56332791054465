import React, { useState, useEffect } from "react";
import { PlanCard } from "../cards/planCard";
import { getPlansRequest } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

export const PlanSection = () => {
  const dispatch = useDispatch();

  const { getPlansSuccess, getPlansError, getPlansLoading, isLoggedIn, plans } =
    useSelector((state) => {
      const {
        success: { getPlans: getPlansSuccess },
        errors: { getPlans: getPlansError },
      } = state.ajaxStatuses;

      const { getPlansLoading } = state.loadingIndicator;

      const { isLoggedIn } = state.userData;

      const { plans } = state.planData;

      return {
        getPlansSuccess,
        getPlansError,
        getPlansLoading,
        isLoggedIn,
        plans,
      };
    });

  useEffect(() => {
    if (getPlansError) {
      toast.error(getPlansError, {
        duration: 3000,
      });
    }
  }, [getPlansError]);

  useEffect(() => {
    dispatch(getPlansRequest());
  }, [getPlansRequest]);

  return (
    <div className="container mx-auto px-4  py-12 text-white relative z-10">
      <h1 className=" text-center text-3xl md:text-5xl font-head font-semibold">
        Investment <span className="text-primary">Plans</span>
      </h1>

      <p className="text-center my-6 max-w-xl mx-auto text-base font-body">
        To make a solid investment, you have to know where you are investing.
        Find a plan which is best for you.
      </p>

      {getPlansLoading ? (
        <div className="flex items-center text-center justify-items-center gap-y-10 max-w-5xl content-center  gap-x-10 mx-auto mt-10 ">
          <CircularProgress
            size={20}
            className="text-primary mx-auto items-center mt-20"
          />
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-y-10 max-w-5xl content-center  gap-x-10 mx-auto mt-10">
          {plans.map((plan, index) => (
            <PlanCard data={plan} isLoggedIn={isLoggedIn} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};
