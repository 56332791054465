import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
// import Admin from "./Admin";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/action";
import jwt_decode from "jwt-decode";
import Home from "./Web/Home";
import Login from "./Web/Login";
import Register from "./Web/Register";
import { PrivateRoute } from "../components/PrivateRoute";
import Faq from "./Web/Faq";
import About from "./Web/About";
import Profile from "./Web/Profile";
import Rules from "./Web/Rules";
import Admin from "./Admin";
// import ForgotPassword from "./Auth/ForgotPassword";
// import ResetPassword from "./Auth/ResetPassword";

const Pages = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => {
    const { token } = state.userData;
    return { token };
  });
  useEffect(() => {
    if (token) {
      const exp = jwt_decode(token).exp;
      const tokenIsValid = !(Date.now() >= exp * 1000);
      if (!tokenIsValid) {
        dispatch(logout());
      }
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/about" component={About} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/rules" component={Rules} />
      {/* <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/resetpassword/:token" component={ResetPassword} /> */}
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Route path="/admin" component={Admin} />
    </Switch>
  );
};

export default Pages;
