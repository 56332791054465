import React from "react";

export const WhyCard = ({ head, Icon, body }) => {
  return (
    <div className="whycard-bg p-4 flex flex-col gap-y-4">
      <div className="flex text-primary items-center gap-x-3">
        <Icon className="text-3xl" />
        <h4 className="font-head text-xl md:text-2xl text-primary">{head}</h4>
      </div>
      <p className="font-body text-md">{body}</p>
    </div>
  );
};
