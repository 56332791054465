import React, { useState, useEffect } from "react";
import { FormError } from "../FormError";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  updateInvestmentRequest,
  clearUpdateInvestmentSuccess,
  getUserInvestmentsRequest,
} from "../../redux/action";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { UserCard } from "../cards/userCard";
import { FormButtonPrimary } from "../buttons";

export const EditInvestmentForm = ({ investment, handleClose }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [active, setActive] = React.useState(false);

  const {
    token,
    updateInvestmentSuccess,
    updateInvestmentError,
    updateInvestmentLoading,
    getUserInvestmentsLoading,
  } = useSelector((state) => {
    const {
      success: { updateInvestment: updateInvestmentSuccess },
      errors: {
        updateInvestment: updateInvestmentError,
        getInvestment: getInvestmentError,
      },
    } = state.ajaxStatuses;

    const { token } = state.userData;

    const { updateInvestmentLoading, getInvestmentLoading } =
      state.loadingIndicator;

    return {
      token,
      updateInvestmentSuccess,
      updateInvestmentError,
      updateInvestmentLoading,
      getInvestmentError,
      getInvestmentLoading,
    };
  });

  useEffect(() => {
    if (updateInvestmentError) {
      toast.error(updateInvestmentError, {
        duration: 3000,
      });
    }
  }, [updateInvestmentError]);

  useEffect(() => {
    if (updateInvestmentSuccess) {
      toast.success(updateInvestmentSuccess, {
        duration: 3000,
      });

      dispatch(getUserInvestmentsRequest(investment.user._id));

      handleClose();

      dispatch(clearUpdateInvestmentSuccess());
    }
  }, [updateInvestmentSuccess]);

  const formik = useFormik({
    initialValues: {
      status: "",
      firstName: "",
      email: "",
      amount: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      const formData = prop;
      formData.firstName = investment.user.firstName;
      formData.email = investment.user.email;
      const id = investment._id;
      const userId = investment.user._id;

      dispatch(updateInvestmentRequest({ formData, userId, id, token }));
    },
  });

  useEffect(() => {
    setActive(investment.isActive);
    formik.setValues((values) => ({
      ...values,
      expectedReturn: investment.expectedReturn
        ? investment.expectedReturn
        : "",
      status: investment.status,
      transactionId: investment.transactionId,
      chain: investment.chain,
      amount: investment.amount,
      expectedReturn: investment.expectedReturn,
      profitProduction: investment.profitProduction,
    }));
  }, []);

  return (
    <div className="p-4 pt-16">
      <h3 className="text-white font-head text-xl md:text-2xl text-center">
        Update User Investment
      </h3>

      {getUserInvestmentsLoading ? (
        <div className="flex items-center justify-center py-16">
          <CircularProgress style={{ color: "black" }} size="20px" />
        </div>
      ) : (
        <UserCard user={investment.user} singlePage={false} />
      )}

      <div className="mt-10">
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Transaction Id:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Transaction Id"
            name="transactionId"
            {...formik.getFieldProps("transactionId")}
          />
          {formik.errors.transactionId && formik.touched.transactionId && (
            <FormError message={formik.errors.transactionId} />
          )}
        </div>

        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Status:</h5>
          <select
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="First name"
            name="status"
            {...formik.getFieldProps("status")}
          >
            <option value="Pending">Pending</option>
            <option value="Active">Active</option>
            <option value="Completed">Completed</option>
          </select>
          {formik.errors.firstName && formik.touched.firstName && (
            <FormError message={formik.errors.firstName} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Amount($):</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Amount"
            name="amount"
            disabled
            {...formik.getFieldProps("amount")}
          />
          {formik.errors.amount && formik.touched.amount && (
            <FormError message={formik.errors.amount} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Expected Return($):</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Expected return"
            name="expectedReturn"
            disabled
            {...formik.getFieldProps("expectedReturn")}
          />
          {formik.errors.expectedReturn && formik.touched.expectedReturn && (
            <FormError message={formik.errors.expectedReturn} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Profit production(%):</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Profit production"
            name="profitProduction"
            disabled
            {...formik.getFieldProps("profitProduction")}
          />
          {formik.errors.profitProduction &&
            formik.touched.profitProduction && (
              <FormError message={formik.errors.profitProduction} />
            )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Chain:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Chain"
            name="chain"
            disabled
            {...formik.getFieldProps("chain")}
          />
          {formik.errors.chain && formik.touched.chain && (
            <FormError message={formik.errors.chain} />
          )}
        </div>

        <div className="mt-5 w-40 mx-auto">
          <FormButtonPrimary
            text={"Update investment"}
            disabled={!formik.isValid}
            action={formik.handleSubmit}
            loading={updateInvestmentLoading}
          />
        </div>
      </div>
    </div>
  );
};
