import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { getUserInvestmentsRequest } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

import { InvestmentCard } from "../../../components/cards/investmentCard";

const DepositHistory = () => {
  const dispatch = useDispatch();

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,

    token,
  } = useSelector((state) => {
    const {
      success: {},
      errors: { getUserInvestments: getUserInvestmentsError },
    } = state.ajaxStatuses;

    const { getUserInvestmentsLoading } = state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      token,
    };
  });

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(user._id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Deposit History"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-body text-xl md:text-2xl text-center">
            Deposite History
          </h3>

          <div className="mt-10">
            {getUserInvestmentsLoading ? (
              <div className="text-white">Loading</div>
            ) : (
              investments.map((investment, index) => (
                <InvestmentCard investment={investment} key={index} />
              ))
            )}
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default DepositHistory;
