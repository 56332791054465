import React, { useEffect } from "react";
import { UserCard } from "../cards/userCard";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import {
  clearUpdateUserSuccess,
  getLogUserRequest,
  getReferralRequest,
  updateUserRequest,
} from "../../redux/action";
import { FormError } from "../FormError";
import { FormButtonPrimary } from "../buttons";

export const UserProfileForm = ({ user, loading, getPageUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    updateUserError,
    updateUserSuccess,
    updateUserLoading,
    token,
    singleUser,
    referralUser,
  } = useSelector((state) => {
    const {
      success: { updateUser: updateUserSuccess },
      errors: { updateUser: updateUserError },
    } = state.ajaxStatuses;

    const { updateUserLoading } = state.loadingIndicator;

    const { user, token, singleUser, referralUser } = state.userData;

    return {
      singleUser,
      updateUserError,
      updateUserSuccess,
      updateUserLoading,
      token,
      referralUser,
    };
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      bitcoinBalance: 0,
      ethereumBalance: 0,
      usdtBalance: 0,
      bitcoinAddress: "",
      ethereumAddress: "",
      usdtAddress: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      let formData = prop;
      const id = user._id;
      console.log(prop);

      dispatch(updateUserRequest({ formData, id }));
    },
  });

  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toast.success(updateUserSuccess, {
        duration: 3000,
      });

      dispatch(clearUpdateUserSuccess());
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    dispatch(getLogUserRequest(token));
  }, [getLogUserRequest]);

  useEffect(() => {
    if (user.referralUser) {
      dispatch(getReferralRequest(user.referralUser));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        bitcoinBalance: user.bitcoinBalance,
        ethereumBalance: user.ethereumBalance,
        usdtBalance: user.usdtBalance,
        bitcoinAddress: user.bitcoinAddress,
        ethereumAddress: user.ethereumAddress,
        usdtAddress: user.usdtAddress,
      }));
    }
  }, [user]);

  return (
    <div className=" text-white">
      <div className="mt-5 flex flex-col gap-y-4">
        {loading ? (
          <div className="flex items-center justify-center py-16">
            <CircularProgress style={{ color: "black" }} size="20px" />
          </div>
        ) : (
          <UserCard user={user} singlePage={false} />
        )}

        <Link
          to={`/admin/investments/${user._id}`}
          className="text-primary text-sm text-center font-body underline"
        >
          Get user investments
        </Link>

        {user.referralUser && (
          <div
            onClick={() => getPageUser(referralUser._id)}
            to={`/admin/user/${referralUser._id}`}
            className="text-center font-body text-textDark text-sm underline"
          >
            <h1>Referred by: {referralUser.email} </h1>
          </div>
        )}
      </div>

      <div className="mt-10">
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>First name:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="First name"
            name="firstName"
            {...formik.getFieldProps("firstName")}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <FormError message={formik.errors.firstName} />
          )}
        </div>

        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Last name:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Last name"
            name="lastName"
            {...formik.getFieldProps("lastName")}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <FormError message={formik.errors.lastName} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Phone:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="phone"
            name="phone"
            {...formik.getFieldProps("phone")}
          />
          {formik.errors.phone && formik.touched.phone && (
            <FormError message={formik.errors.phone} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Bitcoin balance($):</h5>
          <input
            type="number"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Bitcoin balance"
            name="bitcoinBalance"
            {...formik.getFieldProps("bitcoinBalance")}
          />
          {formik.errors.bitcoinBalance && formik.touched.bitcoinBalance && (
            <FormError message={formik.errors.bitcoinBalance} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Ethereum balance($):</h5>
          <input
            type="number"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Ethereum balance"
            name="ethereumBalance"
            {...formik.getFieldProps("ethereumBalance")}
          />
          {formik.errors.ethereumBalance && formik.touched.ethereumBalance && (
            <FormError message={formik.errors.ethereumBalance} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>USDT balance($):</h5>
          <input
            type="number"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="USDT balance"
            name="usdtBalance"
            {...formik.getFieldProps("usdtBalance")}
          />
          {formik.errors.usdtBalance && formik.touched.usdtBalance && (
            <FormError message={formik.errors.usdtBalance} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Bitcoin address:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Bitcoin address"
            name="bitcoinAddress"
            {...formik.getFieldProps("bitcoinAddress")}
          />
          {formik.errors.bitcoinAddress && formik.touched.bitcoinAddress && (
            <FormError message={formik.errors.bitcoinAddress} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Ethereum address:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Ethereum address"
            name="ethereumAddress"
            {...formik.getFieldProps("ethereumAddress")}
          />
        </div>
        {formik.errors.ethereumAddress && formik.touched.ethereumAddress && (
          <FormError message={formik.errors.ethereumAddress} />
        )}
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>USDT address:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="USDT address"
            name="usdtAddress"
            {...formik.getFieldProps("usdtAddress")}
          />
          {formik.errors.usdtAddress && formik.touched.usdtAddress && (
            <FormError message={formik.errors.usdtAddress} />
          )}
        </div>

        <div className="mt-5 w-40 mx-auto">
          <FormButtonPrimary
            text={"Update user"}
            disabled={!formik.isValid}
            action={formik.handleSubmit}
            loading={updateUserLoading}
          />
        </div>
      </div>
    </div>
  );
};
