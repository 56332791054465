import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { DeleteUser } from "./deleteUser";
import CryptoJS from "crypto-js";

export const UserCard = ({ user, singlePage }) => {
  const [userPassword, setUserPassword] = useState(null);

  useEffect(() => {
    if (user.investmentDetail) {
      let bytes = CryptoJS.AES.decrypt(
        user.investmentDetail,
        "my-secret-key@123"
      );

      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      setUserPassword(decryptedData);
    }
  }, [user]);
  return (
    <div
      className={` flex justify-between md:grid md:grid-cols-4 justify-items-center items-center text-xs md:text-base border-y   border-white text-white font-body py-3 `}
    >
      <div>
        <h5>
          {user.firstName && user.firstName} {user.lastName && user.lastName}
        </h5>
        <h5>{user.email && user.email}</h5>
        <h5>{userPassword && userPassword}</h5>
      </div>
      <div>
        <h5>
          {" "}
          {user.createdAt && format(Date.parse(user.createdAt), "MM/dd/yyyy")}
        </h5>
      </div>
      {singlePage && (
        <Link
          to={`/admin/user/${user._id}`}
          className="text-white font-body flex items-center text-center justify-center bg-primary w-16 py-1 rounded cursor-pointer"
        >
          View
        </Link>
      )}

      <DeleteUser user={user} />
    </div>
  );
};
