import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../redux/action";
import { useDispatch } from "react-redux";

const NavItem = ({ text, margin, url }) => {
  return (
    <div className={`border-t  border-t-white w-full p-3  ${margin}`}>
      <Link to={url}>
        <p className="text-blue text-sm md:text-base hover:text-primary transition-all duration-300">
          {text}
        </p>
      </Link>
    </div>
  );
};

export const AdminDashboardNav = () => {
  const dispatch = useDispatch();

  return (
    <div className="mx-auto profit-calculator-wrapper relative z-10 py-10 w-[100%] md:w-[25%]">
      <div className="text-white font-body py-4 px-6 text-center">
        <NavItem text={"Home"} url={"/admin/dashboard"} margin={"0"} />
        <NavItem text={"Deposits"} margin={"0"} />
        <NavItem
          text={"Withdraws"}
          url={"/admin/withdrawals"}
          margin={"mt-4"}
        />
        {/* <NavItem
          text={"Edit Account"}
          url={"/admin/editaccount"}
          margin={"mt-4"}
        /> */}
        <div
          onClick={() => dispatch(logout())}
          className={`border-t border-t-white w-full p-3 `}
        >
          <p className="text-blue cursor-pointer text-base hover:text-primary transition-all duration-300">
            Logout
          </p>
        </div>
      </div>
    </div>
  );
};
