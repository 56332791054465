import React from "react";

export const WebPageHeader = ({ text }) => {
  return (
    <div className="h-[20vh] md:h-[40vh] relative z-10">
      <img
        className="w-full object-cover h-full absolute top-0"
        src="/img/web/headerbg.png"
      />

      <div className="container mx-auto px-4 pt-10 md:pt-40 flex justify-center ">
        <h1 className="text-white relative text-2xl md:text-3xl font-body ">
          {text}
        </h1>
      </div>
    </div>
  );
};
