import React, { useState, useEffect } from "react";
import { FormError } from "../FormError";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  updateWithdrawRequest,
  clearUpdateWithdrawSuccess,
  getUserInvestmentsRequest,
  getWithdrawsRequest,
} from "../../redux/action";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { UserCard } from "../cards/userCard";
import { FormButtonPrimary } from "../buttons";

export const EditWithdrawalForm = ({ data, handleClose }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const {
    token,
    updateWithdrawSuccess,
    updateWithdrawError,
    updateWithdrawLoading,
    getUserInvestmentsLoading,
  } = useSelector((state) => {
    const {
      success: { updateWithdraw: updateWithdrawSuccess },
      errors: {
        updateWithdraw: updateWithdrawError,
        getInvestment: getInvestmentError,
      },
    } = state.ajaxStatuses;

    const { token } = state.userData;

    const { updateWithdrawLoading, getInvestmentLoading } =
      state.loadingIndicator;

    return {
      token,
      updateWithdrawSuccess,
      updateWithdrawError,
      updateWithdrawLoading,
      getInvestmentError,
      getInvestmentLoading,
    };
  });

  useEffect(() => {
    if (updateWithdrawError) {
      toast.error(updateWithdrawError, {
        duration: 3000,
      });
    }
  }, [updateWithdrawError]);

  useEffect(() => {
    if (updateWithdrawSuccess) {
      toast.success(updateWithdrawSuccess, {
        duration: 3000,
      });

      dispatch(getUserInvestmentsRequest(data.user._id));

      dispatch(getWithdrawsRequest());

      dispatch(clearUpdateWithdrawSuccess());
    }
  }, [updateWithdrawSuccess]);

  const formik = useFormik({
    initialValues: {
      status: "",
      userId: "",
      chain: "",
      status: "",
      amount: "",
      walletAddress: "",
      firstName: "",
      email: "",
      transactionRef: "",
    },
    validationSchema: yup.object({}),

    onSubmit: (prop) => {
      const formData = prop;
      formData.userId = data.user._id;
      formData.firstName = data.user.firstName;
      formData.email = data.user.email;
      const id = data._id;

      dispatch(updateWithdrawRequest({ formData, id }));
    },
  });

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      status: data.status,
      amount: data.amount,
      chain: data.chain,
    }));
  }, []);

  return (
    <div className="p-4 pt-16">
      <h3 className="text-white font-head text-xl md:text-2xl text-center">
        Update Withdrawal
      </h3>

      {getUserInvestmentsLoading ? (
        <div className="flex items-center justify-center py-16">
          <CircularProgress style={{ color: "black" }} size="20px" />
        </div>
      ) : (
        <UserCard user={data.user} singlePage={true} />
      )}

      <div className="mt-10">
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Amount($):</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Amount"
            name="amount"
            disabled
            {...formik.getFieldProps("amount")}
          />
          {formik.errors.amount && formik.touched.amount && (
            <FormError message={formik.errors.amount} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Chain:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Chain"
            name="chain"
            disabled
            {...formik.getFieldProps("chain")}
          />
          {formik.errors.chain && formik.touched.chain && (
            <FormError message={formik.errors.chain} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Wallet address:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Wallet address paid to"
            name="walletAddress"
            {...formik.getFieldProps("walletAddress")}
          />
          {formik.errors.walletAddress && formik.touched.walletAddress && (
            <FormError message={formik.errors.walletAddress} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Transaction Ref:</h5>
          <input
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="Transacton ref"
            name="transactionRef"
            {...formik.getFieldProps("transactionRef")}
          />
          {formik.errors.transactionRef && formik.touched.transactionRef && (
            <FormError message={formik.errors.transactionRef} />
          )}
        </div>
        <div
          className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-b  border-white text-white font-body py-3 `}
        >
          <h5>Status:</h5>
          <select
            type="text"
            className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
            placeholder="First name"
            name="status"
            {...formik.getFieldProps("status")}
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Declined">Declined</option>
          </select>
          {formik.errors.status && formik.touched.status && (
            <FormError message={formik.errors.status} />
          )}
        </div>
        <div className="mt-5 w-40 mx-auto">
          <FormButtonPrimary
            text={"Update withdrawal"}
            disabled={!formik.isValid}
            action={formik.handleSubmit}
            loading={updateWithdrawLoading}
          />
        </div>
      </div>
    </div>
  );
};
