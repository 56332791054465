import React from "react";

export const WebPageWrapper = ({ children }) => {
  return (
    <div className=" bg-[#101113]">
      <div className="full-wh"></div>
      <div className="bg-animation z-10">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
      {children}
    </div>
  );
};
