import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import {
  CREATE_WITHDRAW_REQUEST,
  GET_USER_WITHDRAWS_REQUEST,
  GET_WITHDRAWS_REQUEST,
  UPDATE_WITHDRAW_REQUEST,
} from "../types";
import { clientErrorMessage, delay } from "./reusable";
import {
  createWithdrawFailure,
  createWithdrawLoading,
  createWithdrawSuccess,
  getUserWithdrawsFailure,
  getUserWithdrawsLoading,
  getUserWithdrawsSuccess,
  getWithdrawsFailure,
  getWithdrawsLoading,
  getWithdrawsSuccess,
  updateWithdrawFailure,
  updateWithdrawLoading,
  updateWithdrawSuccess,
} from "../action";

const ajaxDBCalls = {
  createWithdraw: async (formData) => {
    const response = await axios.post(`/withdraw/create`, formData);
    return response;
  },
  getWithdraws: async () => {
    const response = await axios.get(`/withdraw`);
    return response;
  },
  getUserWithdraws: async (id) => {
    const response = await axios.get(`/withdraw/user/${id}`);
    return response;
  },
  updateWithdraw: async ({ formData, id }) => {
    const response = await axios.put(`/withdraw/${id}`, formData);
    return response;
  },
};

function* createWithdraw({ payload }) {
  try {
    yield put(createWithdrawLoading(true));

    const res = yield call(ajaxDBCalls.createWithdraw, payload);

    yield put(createWithdrawSuccess(res.data));

    yield put(createWithdrawLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createWithdrawFailure(errorMessage));
    yield put(createWithdrawLoading(false));
    yield delay();
    yield put(createWithdrawFailure(""));
  }
}
function* getWithdraws({ payload }) {
  try {
    yield put(getWithdrawsLoading(true));

    const res = yield call(ajaxDBCalls.getWithdraws, payload);

    yield put(getWithdrawsSuccess(res.data));

    yield put(getWithdrawsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getWithdrawsFailure(errorMessage));
    yield put(getWithdrawsLoading(false));
    yield delay();
    yield put(getWithdrawsFailure(""));
  }
}
function* getUserWithdraws({ payload }) {
  try {
    yield put(getUserWithdrawsLoading(true));

    const res = yield call(ajaxDBCalls.getUserWithdraws, payload);

    yield put(getUserWithdrawsSuccess(res.data));

    yield put(getUserWithdrawsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUserWithdrawsFailure(errorMessage));
    yield put(getUserWithdrawsLoading(false));
    yield delay();
    yield put(getUserWithdrawsFailure(""));
  }
}
function* updateWithdraw({ payload }) {
  try {
    yield put(updateWithdrawLoading(true));

    const res = yield call(ajaxDBCalls.updateWithdraw, payload);

    yield put(updateWithdrawSuccess(res.data));

    yield put(updateWithdrawLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updateWithdrawFailure(errorMessage));
    yield put(updateWithdrawLoading(false));
    yield delay();
    yield put(updateWithdrawFailure(""));
  }
}

//Watchers
function* createWithdrawWatcher() {
  yield takeLatest(CREATE_WITHDRAW_REQUEST, createWithdraw);
}
function* getWithdrawsWatcher() {
  yield takeLatest(GET_WITHDRAWS_REQUEST, getWithdraws);
}
function* getUserWithdrawsWatcher() {
  yield takeLatest(GET_USER_WITHDRAWS_REQUEST, getUserWithdraws);
}
function* updateWithdrawWatcher() {
  yield takeLatest(UPDATE_WITHDRAW_REQUEST, updateWithdraw);
}

export default function* withdrawSagas() {
  yield spawn(createWithdrawWatcher);
  yield spawn(getWithdrawsWatcher);
  yield spawn(getUserWithdrawsWatcher);
  yield spawn(updateWithdrawWatcher);
}
