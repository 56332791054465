import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FormError } from "../FormError";
import {
  updatePasswordRequest,
  clearUpdatePasswordSuccess,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormButtonPrimary } from "../buttons";

export const UpdatePasswordForm = () => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    user,
    updatePasswordError,
    updatePasswordLoading,
    updatePasswordSuccess,
    token,
  } = useSelector((state) => {
    const {
      success: { updatePassword: updatePasswordSuccess },
      errors: { updatePassword: updatePasswordError },
    } = state.ajaxStatuses;

    const { updatePasswordLoading } = state.loadingIndicator;

    const { user, token } = state.userData;

    return {
      user,
      updatePasswordError,
      updatePasswordLoading,
      updatePasswordSuccess,
      token,
    };
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: yup.object({
      password: yup.string().required("Password is required"),
      newPassword: yup.string().required("New Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.newPassword !== confirmPassword) {
        toast.error("Password does not match", {
          duration: 3000,
        });
      } else {
        const formData = prop;

        dispatch(updatePasswordRequest({ formData, token }));
      }
    },
  });

  useEffect(() => {
    if (updatePasswordError) {
      toast.error(updatePasswordError, {
        duration: 3000,
      });
    }
  }, [updatePasswordError]);

  useEffect(() => {
    if (updatePasswordSuccess) {
      toast.success(updatePasswordSuccess, {
        duration: 3000,
      });

      history.push("/login");

      dispatch(clearUpdatePasswordSuccess());
    }
  }, [updatePasswordSuccess]);

  return (
    <div className="mt-10">
      <h3 className="text-white font-body text-2xl text-center">
        Update Password:
      </h3>

      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-y  border-white text-white font-body py-3  mt-6`}
      >
        <h5>Old password:</h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("password")}
        />
        {formik.errors.password && formik.touched.password && (
          <FormError message={formik.errors.password} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>New password: </h5>
        <input
          type="text"
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
          {...formik.getFieldProps("newPassword")}
        />
        {formik.errors.newPassword && formik.touched.newPassword && (
          <FormError message={formik.errors.newPassword} />
        )}
      </div>
      <div
        className={`grid grid-cols-2  text-sm md:text-base justify-items-center place-items-center border-b  border-white text-white font-body py-3  `}
      >
        <h5>New password:</h5>
        <input
          type="text"
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
          placeholder="Enter transaction Id"
        />
      </div>

      <div className="w-24 mt-6 flex justify-center mx-auto">
        <FormButtonPrimary
          text={"Update"}
          action={formik.handleSubmit}
          loading={updatePasswordLoading}
          disabled={!formik.isValid || confirmPassword === ""}
        />
      </div>
    </div>
  );
};
