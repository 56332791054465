import React, { useState } from "react";
import { GoPerson } from "react-icons/go";
import { Link, NavLink, useLocation } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/Drawer";
import { HiMenuAlt1, HiSupport, HiInformationCircle } from "react-icons/hi";
import {
  AiOutlineLogin,
  AiFillHome,
  AiFillQuestionCircle,
  AiOutlineClose,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

const navItems = [
  {
    name: "Home",
  },
  {
    name: "FAQ",
  },
  {
    name: "About",
  },
  {
    name: "Profile",
  },
];

export const WebPageNav = () => {
  const pathname = useLocation().pathname;

  const [sideNav, setsideNav] = useState(false);

  const { isLoggedIn } = useSelector((state) => {
    const { isLoggedIn } = state.userData;

    return {
      isLoggedIn,
    };
  });

  return (
    <>
      {" "}
      <div className="h-28 w-full hidden lg:grid lg:fixed z-20  border-b border-stone-600 backdrop-blur-md">
        <div className="container px-4 auto flex flex-row justify-between items-center">
          <img className="w-32" src="/img/logo/logo.svg" />

          <div className="text-white ml-auto flex gap-x-10">
            {navItems.map(({ name }, index) => (
              <NavLink
                key={index}
                to={"/" + name.toLowerCase()}
                className={`${
                  pathname === `/${name.toLowerCase()}`
                    ? "text-primary"
                    : "text-white"
                } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
              >
                {name}
              </NavLink>
            ))}

            {!isLoggedIn && (
              <>
                <NavLink
                  to={"/login"}
                  className={`${
                    pathname === `/login` ? "text-primary" : "text-white"
                  } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
                >
                  Login
                </NavLink>
                <NavLink
                  to={"/register"}
                  className={`${
                    pathname === `/register` ? "text-primary" : "text-white"
                  } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
                >
                  Register
                </NavLink>
              </>
            )}
          </div>

          <div className=" mt-5 ml-auto bg-primary w-12 h-12 flex items-center justify-center rounded-lg cursor-pointer">
            <Link to="/dashboard/home">
              <GoPerson className="text-white text-lg" />
            </Link>
          </div>
        </div>
      </div>
      <div className=" w-full z-20  h-20  relative border-b lg:hidden border-stone-600 backdrop-blur-md">
        <div>
          <div
            className="text-2xl cursor-pointer text-black pt-4 pr-4 absolute right-0"
            onClick={() => setsideNav(!sideNav)}
          >
            <HiMenuAlt1 className="cursor-pointer text-white" />
          </div>

          <SwipeableDrawer
            anchor={"top"}
            open={sideNav}
            onClose={() => setsideNav(false)}
            onOpen={() => setsideNav(true)}
          >
            <div className="bg-black h-screen w-screen text-lightGray  relative">
              <div
                onClick={() => setsideNav(!sideNav)}
                className="ml-auto text-2xl text-black absolute right-4 top-4 cursor-pointer"
              >
                <AiOutlineClose className="text-white" />
              </div>

              <div className="flex flex-col pt-10 gap-4 pl-6">
                {navItems.map(({ name, href, Icon }, index) => (
                  <NavLink
                    to={"/" + name.toLowerCase()}
                    key={index}
                    onClick={() => setsideNav(false)}
                    className={`${
                      pathname === `/${name.toLowerCase()}`
                        ? "text-primary"
                        : "text-white"
                    } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
                  >
                    {name}
                  </NavLink>
                ))}

                {!isLoggedIn && (
                  <>
                    <NavLink
                      to={"/login"}
                      className={`${
                        pathname === `/login` ? "text-primary" : "text-white"
                      } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
                    >
                      Login
                    </NavLink>
                    <NavLink
                      to={"/register"}
                      className={`${
                        pathname === `/register` ? "text-primary" : "text-white"
                      } font-body text-base cursor-pointer hover:text-primary  transition-all duration-300`}
                    >
                      Register
                    </NavLink>
                  </>
                )}

                <div className=" mt-5 bg-primary w-12 h-12 flex items-center justify-center rounded-lg cursor-pointer">
                  <Link to="/dashboard/home" className="">
                    <GoPerson className="text-white text-lg" />
                  </Link>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        </div>
        <div className=" text-center">
          <div className="mx-auto z-40 flex w-full justify-between pt-4 pl-4">
            <Link to="/">
              <img className="w-32" src="/img/logo/logo.svg" />
            </Link>

            {/* <img src="/img/flag/usa.svg" className="w-8" /> */}
          </div>
        </div>
      </div>
    </>
  );
};
