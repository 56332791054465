import React from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";

const faqQuestionsAndAnswer = [
  {
    question: "How can I invest with Tencent Investment ?",
    answer:
      "To make a investment you must first become a member of Tencent Investment . Once you are signed up, you can make your first deposit. All deposits must be made through the Members Area. You can login using the member username and password you receive when signup.",
  },
  {
    question: "How do I open my Tencent Investment Account?",
    answer: `It's quite easy and convenient. Follow this link, fill in the registration form and then press "Register".`,
  },
  {
    question: "Which e-currencies do you accept?",
    answer: "We accept bitcoin and Ethereum.",
  },
  {
    question: "How can I withdraw funds?",
    answer:
      "Login to your account using your username and password and check the Withdraw section.",
  },
  {
    question: "How long does it take for my deposit to be added to my account?",
    answer: "Your account will be updated as fast, as you deposit.",
  },
  {
    question: "How can I change my e-mail address or password?",
    answer: `Log into your Tencent Investment account and click on the "Account Information". You can change your e-mail address and password there.`,
  },
  {
    question:
      "What if I can't log into my account because I forgot my password?",
    answer: `Click forgot password link, type your username or e-mail and you'll receive your account information.`,
  },
  {
    question: "Does a daily profit paid directly to my currency account?",
    answer: `No, profits are gathered on your Tencent Investment account and you can withdraw them anytime.`,
  },
  {
    question: "How do you calculate the interest on my account?",
    answer: `Depending on each plan. Interest on your Tencent Investment account is acquired Daily, Weekly, Bi-Weekly, Monthly and Yearly and credited to your available balance at the end of each day.`,
  },
  {
    question: "Can I do a direct deposit from my account balance?",
    answer: `Yes! To make a deposit from your Tencent Investment account balance. Simply login into your members account and click on Make Deposit ans select the Deposit from Account Balance Radio button.`,
  },
  {
    question:
      "Can I make an additional deposit to Tencent Investment account once it has been opened?",
    answer: `Yes, you can but all transactions are handled separately.`,
  },
  {
    question:
      "After I make a withdrawal request, when will the funds be available on my ecurrency account?",
    answer: `Funds are usually available within 12 business hours.`,
  },
  {
    question: "How can I change my password?",
    answer: `You can change your password directly from your members area by editing it in your personal profile.`,
  },
  {
    question: "Can I lose money?",
    answer: `There is a risk involved with investing in all high yield investment programs. However, there are a few simple ways that can help you to reduce the risk of losing more than you can afford to. First, align your investments with your financial goals, in other words, keep the money you may need for the short-term out of more aggressive investments, reserving those investment funds for the money you intend to raise over the long-term. It's very important for you to know that we are real traders and that we invest members' funds on major investments.`,
  },
  {
    question: "How can I check my account balances?",
    answer: `You can access the account information 24 hours, seven days a week over the Internet.`,
  },
  {
    question: "May I open several accounts in your program?",
    answer: `No. If we find that one member has more than one account, the entire funds will be frozen.`,
  },
  {
    question: "How can I make a spend?",
    answer: `To make a spend you must first become a member of Tencent Investment . Once you are signed up, you can make your first spend. All spends must be made through the Member Area. You can login using the member username and password you received when signup.`,
  },
  {
    question: "Who manages the funds?",
    answer: `These funds are managed by a team of Tencent Investment investment experts.`,
  },
];

const Faq = () => {
  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"FAQ"} />

      <div className=" container mx-auto max-w-4xl py-20 px-4">
        <h1 className="relative text-white text-xl font-body">FAQ</h1>
        <div className="flex flex-col gap-y-6 text-white mt-6">
          {faqQuestionsAndAnswer.map(({ question, answer }) => (
            <div className="">
              <h4 className="font-body text-base ">{question}</h4>
              <p className="font-body text-base">{answer}</p>
            </div>
          ))}
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Faq;
