import React from "react";
import { Switch, Route } from "react-router-dom";
// import Plans from "./Plans";
// import Payment from "./Payment";
import Home from "./Home";
import MakeDeposit from "./MakeDeposit";
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import Settings from "./settings";
import DepositHistory from "./DepositHistory";
import WithdrawalHistory from "./WithdrawalHistory";
import Referrals from "./Referrals";
// import Proceed from "./Proceed";
// import Account from "./Account";
// import Withdraw from "./Withdraw";
// import Withdrawals from "./Withdrawals";

const Dashboard = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/home`}>
        <Home />
      </Route>
      <Route exact path={`${path}/makedeposit`}>
        <MakeDeposit />
      </Route>
      <Route exact path={`${path}/deposit`}>
        <Deposit />
      </Route>
      <Route exact path={`${path}/deposithistory`}>
        <DepositHistory />
      </Route>
      <Route exact path={`${path}/withdrawal`}>
        <Withdrawal />
      </Route>
      <Route exact path={`${path}/withdrawalhistory`}>
        <WithdrawalHistory />
      </Route>
      <Route exact path={`${path}/editaccount`}>
        <Settings />
      </Route>
      <Route exact path={`${path}/referrals`}>
        <Referrals />
      </Route>
    </Switch>
  );
};

export default Dashboard;
