import React, { useState, useEffect } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { FormButtonPrimary } from "../../../components/buttons";
import {
  clearCreateWithdrawSuccess,
  createWithdrawRequest,
} from "../../../redux/action";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { FormError } from "../../../components/FormError";

const Withdrawal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(0);

  const [error, setError] = useState("");

  const handleChange = (e) => {
    e = Number(e);
    console.log(formik.values.chain);
    if (formik.values.chain === "") {
      setError("Select account to withdraw from");
    } else if (e < 0) {
      setError("Enter a positive number");
    } else if (e > 10000000000) {
      setError("Invalid number");
    } else if (e < 10) {
      setError("minimum withdrawal is $10");
    } else if (formik.values.chain === "Bitcoin" && e > user.bitcoinBalance) {
      setError("amount greater than user balance");
    } else if (formik.values.chain === "Ethereum" && e > user.ethereumBalance) {
      setError("amount greater than user balance");
    } else if (formik.values.chain === "USDT(TRC20)" && e > user.usdtBalance) {
      setError("amount greater than user balance");
    } else {
      setError("");
      formik.setFieldValue("amount", e);
    }
  };

  const {
    user,
    createWithdrawLoading,
    createWithdrawSuccess,
    createWithdrawError,
  } = useSelector((state) => {
    const {
      success: { createWithdraw: createWithdrawSuccess },
      errors: { createWithdraw: createWithdrawError },
    } = state.ajaxStatuses;

    const { createWithdrawLoading } = state.loadingIndicator;

    const { user } = state.userData;

    return {
      user,
      createWithdrawLoading,
      createWithdrawSuccess,
      createWithdrawError,
    };
  });

  const formik = useFormik({
    initialValues: {
      chain: "",
      amount: "",
      firstName: "",
      email: "",
    },
    validationSchema: yup.object({
      chain: yup.string().required("chain is required"),
    }),

    onSubmit: (prop) => {
      prop.user = user._id;
      prop.firstName = user.firstName;
      prop.email = user.email;

      if (!error) {
        dispatch(createWithdrawRequest(prop));
      }
    },
  });

  useEffect(() => {
    if (createWithdrawError) {
      toast.error(createWithdrawError, {
        duration: 3000,
      });
    }
  }, [createWithdrawError]);

  useEffect(() => {
    if (createWithdrawSuccess) {
      toast.success(createWithdrawSuccess, {
        duration: 3000,
      });

      history.push("/dashboard/home");

      dispatch(clearCreateWithdrawSuccess());
    }
  }, [createWithdrawSuccess]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Withdrawal"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />

        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-head text-2xl text-center">
            Ask for Withdrawal:
          </h3>

          <div className="mt-6">
            <div>
              <div
                className={`grid grid-cols-2 justify-items-center  border-t text-xs md:text-sm  border-white text-white font-body py-3 `}
              >
                <h5>Account Balance:</h5>
                <h5>$0.00</h5>
              </div>
              <div
                className={`grid grid-cols-2 justify-items-center border-b text-xs md:text-sm  border-white text-white font-body py-3 `}
              >
                <h5>Pending Withdrawals:</h5>
                <h5>$</h5>
              </div>

              <div
                className={`grid grid-cols-3 gap-x-5  md:grid md:grid-cols-2 justify-between  bg-primary  border-t text-xs md:text-sm  border-white text-white font-body py-3  `}
              >
                <div className="grid grid-cols-3 col-span-2  justify-items-start">
                  <h5>Processing</h5>
                  <h5>Available</h5>
                  <h5>Pending</h5>
                </div>
                <div className="justify-self-start">Address</div>
              </div>
              <div
                className={`grid grid-cols-3 gap-x-5 md:grid md:grid-cols-2  justify-between  border-t text-xs md:text-sm  border-white text-white font-body py-3 `}
              >
                <div className="grid grid-cols-3 col-span-2 justify-items-start">
                  <h5>Bitcoin</h5>
                  <h5 className="text-green-500">
                    ${user.bitcoinBalance ? user.bitcoinBalance : "0.00"}
                  </h5>
                  <h5 className="text-red-600">$0.00</h5>
                </div>
                {user.bitcoinAddress ? (
                  <div className="justify-self-start">
                    {user.bitcoinAddress.slice(0, 5)}....
                    {user.bitcoinAddress.slice(
                      user.bitcoinAddress.length - 5,
                      user.bitcoinAddress.length
                    )}
                  </div>
                ) : (
                  <Link
                    to={"/dashboard/editaccount"}
                    className="text-blue underline text-xs font-body justify-self-start"
                  >
                    Update
                  </Link>
                )}
              </div>
              <div
                className={`grid grid-cols-3 gap-x-5 md:grid md:grid-cols-2  justify-between  border-t text-xs md:text-sm  border-white text-white font-body py-3 `}
              >
                <div className="grid grid-cols-3 col-span-2 justify-items-start">
                  <h5>Ethereum</h5>
                  <h5 className="text-green-500">
                    {" "}
                    ${user.ethereumBalance ? user.ethereumBalance : "0.00"}
                  </h5>
                  <h5 className="text-red-600">$0.00</h5>
                </div>
                {user.ethereumAddress ? (
                  <div className="justify-self-start">
                    {user.ethereumAddress.slice(0, 5)}....
                    {user.ethereumAddress.slice(
                      user.ethereumAddress.length - 5,
                      user.ethereumAddress.length
                    )}
                  </div>
                ) : (
                  <Link
                    to={"/dashboard/editaccount"}
                    className="text-blue underline text-xs font-body justify-self-start"
                  >
                    Update
                  </Link>
                )}
              </div>
              <div
                className={`grid grid-cols-3 gap-x-5 md:grid md:grid-cols-2  justify-between  border-t text-xs md:text-sm  border-white text-white font-body py-3 `}
              >
                <div className="grid grid-cols-3 col-span-2 justify-items-start">
                  <h5>Usdt(trc20)</h5>
                  <h5 className="text-green-500">
                    {" "}
                    ${user.usdtBalance ? user.usdtBalance : "0.00"}
                  </h5>
                  <h5 className="text-red-600">$0.00</h5>
                </div>
                {user.usdtAddress ? (
                  <div className="justify-self-start">
                    {user.usdtAddress.slice(0, 5)}....
                    {user.usdtAddress.slice(
                      user.usdtAddress.length - 5,
                      user.usdtAddress.length
                    )}
                  </div>
                ) : (
                  <Link
                    to={"/dashboard/editaccount"}
                    className="text-blue underline text-xs font-body justify-self-start"
                  >
                    Update
                  </Link>
                )}
              </div>
            </div>

            <div className="mt-12">
              <div
                className={`text-center text-xs md:text-base grid md:grid-cols-2 justify-items-center items-center border-t  border-white text-white font-body py-3 `}
              >
                <h5>Select withdraw account:</h5>
                <select
                  type="number"
                  className="py-2 h-9 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="0.00"
                  {...formik.getFieldProps("chain")}
                >
                  <option>Select account to withdraw from</option>
                  <option value="Bitcoin">Bitcoin</option>
                  <option value="Ethereum">Ethereum</option>
                  <option value="USDT(TRC20)">Usdt(trc20)</option>
                </select>
                {formik.errors.chain && formik.touched.chain && (
                  <FormError message={formik.errors.chain} />
                )}
              </div>
              <div
                className={`text-center text-xs md:text-base grid md:grid-cols-2 justify-items-center items-center border-b  border-white text-white font-body py-3 `}
              >
                <h5>Amount to Spend ($):</h5>
                <input
                  type="number"
                  className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="0.00"
                  onChange={(e) => {
                    setAmount(Number(e.target.value));
                    handleChange(e.target.value);
                  }}
                />
                {error && <FormError message={error} />}
              </div>

              <div className="w-28 mt-6 flex justify-center mx-auto">
                <FormButtonPrimary
                  text={"Withdraw"}
                  action={formik.handleSubmit}
                  loading={createWithdrawLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Withdrawal;
