import React from "react";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";

const Profile = () => {
  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Profile"} />

      <div className="container mx-auto max-w-4xl py-24 px-4 relative grid md:grid-cols-3 z-10 gap-x-2 text-white">
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <img className="" src="/img/web/profile/1.jpeg" />
            <p className="font-body text-center">Hubert Karl</p>
          </div>
          <p className="font-body">
            Hubert Karl is one of the Co-founders of tencentInvestment. He was
            born in 1969 in BLOEMENDAAL,in the province of NORTH HOLLAND.A
            Solicitor combined with economics.Hubert Karl ventured into crypto
            currency at early age with the idea of controlling inflation in his
            State. Known for:Economist,Broker, Solicitor
          </p>
        </div>
        <div className="flex flex-col gap-y-2">
          <div>
            <p className="font-body ">Born:09 March 1969</p>
            <p className="font-body ">Nationality, Dutch.</p>
          </div>
          <img className="" src="/img/web/profile/2.jpeg" />
          <img className="" src="/img/web/profile/3.jpeg" />
        </div>
        <div className="flex flex-col gap-y-2">
          <img className="" src="/img/web/profile/4.jpeg" />
          <img className="" src="/img/web/profile/5.jpeg" />
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Profile;
