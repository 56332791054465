import {
  GET_USER_INVESTMENTS_REQUEST,
  GET_USER_INVESTMENTS_SUCCESS,
  GET_USER_INVESTMENTS_FAIL,
  GET_USER_INVESTMENTS_LOADING,
  GET_INVESTMENT_REQUEST,
  GET_INVESTMENT_SUCCESS,
  GET_INVESTMENT_FAIL,
  GET_INVESTMENT_LOADING,
  CREATE_INVESTMENT_REQUEST,
  CREATE_INVESTMENT_SUCCESS,
  CREATE_INVESTMENT_FAIL,
  CREATE_INVESTMENT_LOADING,
  CLEAR_CREATE_INVESTMENT_SUCCESS,
  UPDATE_INVESTMENT_REQUEST,
  UPDATE_INVESTMENT_SUCCESS,
  CLEAR_UPDATE_INVESTMENT_SUCCESS,
  UPDATE_INVESTMENT_FAIL,
  UPDATE_INVESTMENT_LOADING,
} from "../types";

export const getUserInvestmentsRequest = (prop) => {
  return {
    type: GET_USER_INVESTMENTS_REQUEST,
    payload: prop,
  };
};

export const getUserInvestmentsSuccess = (prop) => {
  return {
    type: GET_USER_INVESTMENTS_SUCCESS,
    payload: prop,
  };
};

export const getUserInvestmentsFailure = (errors) => {
  return {
    type: GET_USER_INVESTMENTS_FAIL,
    payload: { errors },
  };
};

export const getUserInvestmentsLoading = (loading) => {
  return {
    type: GET_USER_INVESTMENTS_LOADING,
    payload: loading,
  };
};

export const getInvestmentRequest = (prop) => {
  return {
    type: GET_INVESTMENT_REQUEST,
    payload: prop,
  };
};

export const getInvestmentSuccess = (prop) => {
  return {
    type: GET_INVESTMENT_SUCCESS,
    payload: prop,
  };
};

export const getInvestmentFailure = (errors) => {
  return {
    type: GET_INVESTMENT_FAIL,
    payload: { errors },
  };
};

export const getInvestmentLoading = (loading) => {
  return {
    type: GET_INVESTMENT_LOADING,
    payload: loading,
  };
};

export const createInvestmentRequest = (prop) => {
  return {
    type: CREATE_INVESTMENT_REQUEST,
    payload: prop,
  };
};

export const createInvestmentSuccess = (prop) => {
  return {
    type: CREATE_INVESTMENT_SUCCESS,
    payload: prop,
  };
};

export const clearCreateInvestmentSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_INVESTMENT_SUCCESS,
    payload: prop,
  };
};

export const createInvestmentFailure = (errors) => {
  return {
    type: CREATE_INVESTMENT_FAIL,
    payload: { errors },
  };
};

export const createInvestmentLoading = (loading) => {
  return {
    type: CREATE_INVESTMENT_LOADING,
    payload: loading,
  };
};

export const updateInvestmentRequest = (prop) => {
  return {
    type: UPDATE_INVESTMENT_REQUEST,
    payload: prop,
  };
};

export const updateInvestmentSuccess = (prop) => {
  return {
    type: UPDATE_INVESTMENT_SUCCESS,
    payload: prop,
  };
};

export const clearUpdateInvestmentSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_INVESTMENT_SUCCESS,
    payload: prop,
  };
};

export const updateInvestmentFailure = (errors) => {
  return {
    type: UPDATE_INVESTMENT_FAIL,
    payload: { errors },
  };
};

export const updateInvestmentLoading = (loading) => {
  return {
    type: UPDATE_INVESTMENT_LOADING,
    payload: loading,
  };
};
