import React from "react";
import { Link } from "react-router-dom";

export const WebpageFooter = () => {
  return (
    <>
      {" "}
      <section className="h-[50vh] relative footer-border z-10">
        <img
          className="mx-auto w-full h-full absolute object-cover"
          src="/img/web/startedbg.jpg"
        />

        <div className="backdrop-brightness-50 h-full text-white">
          <div className="container mx-auto px-4 pt-16 text-center">
            <img className="w-32 h-auto mx-auto" src="/img/logo/logo.svg" />

            <div className="flex flex-col mt-3 gap-y-4">
              <h1 className="font-head text-base md:text-xl">
                Tencent Investment
              </h1>
              <h1 className="font-head text-base md:text-xl">
                Tencent Building, Zhongqu First Road, Hi-Tech Park, Nanshan
                District, Shenzhen, Guangdong, 518054. China
              </h1>

              <p className="font-body text-base md:text-xl text-lightGray">
                About us{" "}
                <Link to="/rules" className="text-blue underline">
                  Terms & Condition
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className=" text-center md:text-left px-4 py-2 bg-dark text-white relative z-10">
        <h1 className="font-body text-sm md:text-md">
          © 2021 <span className="text-primary">Tencent Investment</span>. All
          rights reserved
        </h1>
      </section>
    </>
  );
};
