import { CircularProgress } from "@material-ui/core";
import React from "react";

export const WebPageButton = ({ text }) => {
  return (
    <div className="button bg-primary text-dark w-full rounded-lg font-body py-3 px-6 flex item-center justify-center cursor-pointer hover:bg-darkPrimary transition-all duration-300">
      {text}
    </div>
  );
};

export const FormButtonPrimary = ({ loading, disable, action, text }) => {
  return (
    <div
      onClick={disable ? () => {} : action}
      className="button bg-primary text-sm md:text-base text-dark w-full rounded-lg font-body py-2 md:py-3 px-3 md:px-6 flex item-center justify-center cursor-pointer hover:bg-darkPrimary transition-all duration-300"
    >
      {loading ? (
        <CircularProgress style={{ color: "black" }} size="20px" />
      ) : (
        `${text}`
      )}
    </div>
  );
};
