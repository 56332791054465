import React, { useEffect } from "react";
import { getUserInvestmentsRequest } from "../../../redux/action";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { AdminDashboardNav } from "../../../components/Nav/adminDashboardNav";
import AdminUserInvestmentForm from "../../../components/Forms/adminUserInvestmentForm";
import { CircularProgress } from "@material-ui/core";

const UserInvestments = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
    claimInvestmentSuccess,
    claimInvestmentError,
    claimInvestmentLoading,
    token,
  } = useSelector((state) => {
    const {
      success: { claimInvestment: claimInvestmentSuccess },
      errors: {
        getUserInvestments: getUserInvestmentsError,
        claimInvestment: claimInvestmentError,
      },
    } = state.ajaxStatuses;

    const { getUserInvestmentsLoading, claimInvestmentLoading } =
      state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      claimInvestmentSuccess,
      claimInvestmentError,
      claimInvestmentLoading,
      token,
    };
  });

  useEffect(() => {
    dispatch(getUserInvestmentsRequest(id));
  }, [getUserInvestmentsRequest]);

  useEffect(() => {
    if (getUserInvestmentsError) {
      toast.error(getUserInvestmentsError, {
        duration: 3000,
      });
    }
  }, [getUserInvestmentsError]);

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Users"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <AdminDashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          {getUserInvestmentsLoading ? (
            <CircularProgress size={20} className="my-10 mx-auto text-center" />
          ) : (
            <>
              {investments.map((investment, index) => (
                <AdminUserInvestmentForm investment={investment} key={index} />
              ))}
            </>
          )}
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default UserInvestments;
