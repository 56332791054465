import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import { DashboardPlanCard } from "../../../components/cards/dashboardPlanCard";
import { FormButtonPrimary } from "../../../components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { FormError } from "../../../components/FormError";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearCreateInvestmentSuccess,
  createInvestmentRequest,
} from "../../../redux/action";

const MakeDeposit = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const setCheckedChain = (e) => {
    setReinvest(false);
    setChain(e.target.value);
  };
  const setCheckedReinvestChain = (e) => {
    setReinvest(true);
    setChain(e.target.value);
  };

  const [payout, setPayout] = useState(0);
  const [payout5, setPayout5] = useState(0);
  const [newPlan, setPlan] = useState("");
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState(0);
  const [chain, setChain] = useState("");
  const [reinvest, setReinvest] = useState(false);

  const handleChange = (e) => {
    e = Number(e);
    if (e < 0) {
      setError("Enter a positive number");
      setPayout(0);
      setPayout5(0);
      setPlan("");
    } else if (e > 0 && e < 50) {
      setError("Min Investment is $50");
      setPayout(0);
      setPayout5(0);
      setPlan("");
    } else if (e > 10000000000) {
      setError("Invalid number");
      setPayout(0);
      setPayout5(0);
      setPlan("");
    } else if (
      reinvest &&
      chain === "Bitcoin" &&
      e > Number(user.bitcoinBalance)
    ) {
      setError("Amount greater than users bitcoin balance");
      setPayout(0);
      setPlan("");
    } else if (
      reinvest &&
      chain === "Ethereum" &&
      e > Number(user.ethereumBalance)
    ) {
      setError("Amount greater than users ethereum  balance");
      setPayout(0);
      setPlan("");
    } else if (
      reinvest &&
      chain === "USDT(TRC20)" &&
      e > Number(user.usdtBalance)
    ) {
      setError("Amount greater than users USDT balance");
      setPayout(0);
      setPlan("");
    } else {
      setError("");
      if (e >= 50 && e < 4999) {
        setPercent(5);
        setPlan("First Package");
        let profit = e * 0.05;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        // formik.setFieldValue("amount", e);
      } else if (e >= 5000 && e < 9999) {
        setPercent(10);
        setPlan("Second Package");
        let profit = e * 0.1;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        // formik.setFieldValue("amount", e);
      } else if (e >= 10000 && e < 1000000) {
        setPercent(15);
        setPlan("Third Package");
        let profit = e * 0.15;
        setPayout(e + profit);
        setPayout5(e + profit * 5);
        // formik.setFieldValue("amount", e);
      } else {
        setPayout(0);
      }
    }
  };

  const {
    user,
    getUserInvestmentsError,
    investments,
    getUserInvestmentsLoading,
    claimInvestmentSuccess,
    claimInvestmentError,
    claimInvestmentLoading,
    createInvestmentSuccess,
    createInvestmentError,
    createInvestmentLoading,
    token,
  } = useSelector((state) => {
    const {
      success: {
        claimInvestment: claimInvestmentSuccess,
        createInvestment: createInvestmentSuccess,
      },
      errors: {
        getUserInvestments: getUserInvestmentsError,
        claimInvestment: claimInvestmentError,
        createInvestment: createInvestmentError,
      },
    } = state.ajaxStatuses;

    const {
      getUserInvestmentsLoading,
      claimInvestmentLoading,
      createInvestmentLoading,
    } = state.loadingIndicator;

    const { user, token } = state.userData;

    const { investments } = state.investmentData;

    return {
      user,
      getUserInvestmentsError,
      investments,
      getUserInvestmentsLoading,
      claimInvestmentSuccess,
      claimInvestmentError,
      claimInvestmentLoading,
      createInvestmentSuccess,
      createInvestmentError,
      createInvestmentLoading,
      token,
    };
  });

  useEffect(() => {
    if (createInvestmentError) {
      toast.error(createInvestmentError, {
        duration: 3000,
      });
    }
  }, [createInvestmentError]);

  useEffect(() => {
    if (createInvestmentSuccess) {
      toast.success(createInvestmentSuccess, {
        duration: 3000,
      });

      history.push("/dashboard/home");

      dispatch(clearCreateInvestmentSuccess());
    }
  }, [createInvestmentSuccess]);

  const handleSubmit = () => {
    if (reinvest) {
      if (chain === "Bitcoin" && amount > Number(user.bitcoinBalance)) {
        setError("Amount greater than users bitcoin balance");
        setPayout(0);
      } else if (
        reinvest &&
        chain === "Ethereum" &&
        amount > Number(user.ethereumBalance)
      ) {
        setError("Amount greater than users ethereum  balance");
        setPayout(0);
      } else if (chain === "USDT(TRC20)" && amount > Number(user.usdtBalance)) {
        setError("Amount greater than users USDT balance");
      } else {
        let formdata = {};

        formdata.user = user._id;
        formdata.firstName = user.firstName;
        formdata.email = user.email;
        formdata.amount = amount;
        formdata.chain = chain;
        formdata.expectedReturn = payout;
        formdata.profitProduction = percent;
        formdata.planName = newPlan;
        formdata.reinvest = reinvest;
        formdata.transactionId = "";

        dispatch(createInvestmentRequest(formdata));
      }
    } else {
      if (error === "" && amount > 0 && chain !== "") {
        history.push("/dashboard/deposit", {
          payout,
          payout5,
          newPlan,
          percent,
          amount,
          chain,
        });
      }
    }
  };

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Make Deposit"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%] overflow-y-scroll">
          <h3 className="text-white font-head text-2xl text-center">
            Make a Deposite:
          </h3>

          <h6 className="text-white font-body text-base text-center mt-10">
            Select a plan:
          </h6>

          <div className="flex flex-col gap-y-16">
            <DashboardPlanCard
              planName={"First Package"}
              minAmount={50}
              maxAmount={4999}
              profitPercent={5}
            />
            <DashboardPlanCard
              planName={"Second Package"}
              minAmount={5000.0}
              maxAmount={9999}
              profitPercent={10}
            />
            <DashboardPlanCard
              planName={"Third Package"}
              minAmount={10000}
              maxAmount={10000000}
              profitPercent={15}
            />
          </div>

          <div className="mt-20">
            <div
              className={`text-center grid grid-cols-2  justify-items-center text-xs md:text-base border-y  border-white text-white font-body py-3 `}
            >
              <h5>Your account balance ($):</h5>
              <h5>0.00</h5>
            </div>

            <div className="mt-6">
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-y  border-white text-white font-body py-3 `}
              >
                <input
                  id="Bitcoin"
                  type="radio"
                  value="Bitcoin"
                  name="list-radio"
                  onClick={(e) => setCheckedReinvestChain(e)}
                ></input>
                <h5>Reinvest funds from Bitcoin</h5>
              </div>
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
              >
                <input
                  id="Ethereum"
                  type="radio"
                  value="Ethereum"
                  name="list-radio"
                  onClick={(e) => setCheckedReinvestChain(e)}
                ></input>
                <h5>Reinvest funds from Ethereum</h5>
              </div>
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
              >
                <input
                  id="usdt"
                  type="radio"
                  value="USDT(TRC20)"
                  name="list-radio"
                  onClick={(e) => setCheckedReinvestChain(e)}
                ></input>
                <h5>Reinvest funds from USDT(TRC20)</h5>
              </div>
            </div>

            {/* <div className="w-20 mt-6 flex justify-center mx-auto">
              <FormButtonPrimary text={"Rei"} action={pushToDepositPage} />
            </div> */}
            <div className="mt-6">
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-y  border-white text-white font-body py-3 `}
              >
                <input
                  id="Bitcoin"
                  type="radio"
                  value="Bitcoin"
                  name="list-radio"
                  onClick={(e) => setCheckedChain(e)}
                ></input>
                <h5>Spend funds from Bitcoin</h5>
              </div>
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
              >
                <input
                  id="Ethereum"
                  type="radio"
                  value="Ethereum"
                  name="list-radio"
                  onClick={(e) => setCheckedChain(e)}
                ></input>
                <h5>Spend funds from Ethereum</h5>
              </div>
              <div
                className={`text-center flex justify-around md:grid md:grid-cols-2 justify-items-center text-xs md:text-base border-b  border-white text-white font-body py-3 `}
              >
                <input
                  id="usdt"
                  type="radio"
                  value="USDT(TRC20)"
                  name="list-radio"
                  onClick={(e) => setCheckedChain(e)}
                ></input>
                <h5>Spend funds from USDT(TRC20)</h5>
              </div>
            </div>

            <div
              className={`text-center grid grid-cols-2 justify-items-center text-xs md:text-base items-center border-y mt-12  border-white text-white font-body py-3 `}
            >
              <h5>{`Amount to ${reinvest ? "Reinvest" : "spend"} ($):`}</h5>

              <div>
                <input
                  type="number"
                  // value={amount}
                  className="py-2 px-4 w-full rounded-md bg-white border border-formBorder text-black   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="0.00"
                  onChange={(e) => {
                    handleChange(e.target.value);
                    setAmount(Number(e.target.value));
                  }}
                />
                {error && <FormError message={error} />}
              </div>
            </div>

            <div className="w-20 mt-6 flex justify-center mx-auto">
              <FormButtonPrimary
                text={reinvest ? "Reinvest" : "Spend"}
                loading={createInvestmentLoading}
                action={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default MakeDeposit;
