import React, { useState, useEffect } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { FormError } from "../../../components/FormError";
import { FormButtonPrimary } from "../../../components/buttons";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  registerRequest,
  clearRegisterSuccess,
  getUserRequest,
} from "../../../redux/action";
import CryptoJS from "crypto-js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = () => {
  const [checked, setChecked] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [userRef, setUserRef] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getUserLoading,
    getUserError,
    registerSuccess,
    registerError,
    registerLoading,
    user,
    singleUser,
  } = useSelector((state) => {
    const {
      success: { register: registerSuccess },
      errors: { getUser: getUserError, register: registerError },
    } = state.ajaxStatuses;

    const { registerLoading, getUserLoading } = state.loadingIndicator;

    const { user, singleUser } = state.userData;

    return {
      getUserLoading,
      getUserError,
      registerSuccess,
      registerError,
      registerLoading,
      user,
      singleUser,
    };
  });

  let query = useQuery();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      bitcoinAddress: "",
      ethereumAddress: "",
      usdtAddress: "",
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required("first name is required")
        .max(32, "first name is too long")
        .trim()
        .matches(
          /^[a-zA-Z]+$/,
          "first name must be alphabetic without spacing"
        ),
      lastName: yup
        .string()
        .required("last name is required")
        .max(32, "last name is too long")
        .trim()
        .matches(/^[a-zA-Z]+$/, "last name must be alphabetic without spacing"),

      phone: yup.string().required("Phone is required"),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.password !== confirmPassword) {
        toast.error("Password does not match");
      } else {
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(prop.password),
          "my-secret-key@123"
        ).toString();
        prop.investmentDetail = encrypted;

        console.log(prop);
        let formData = prop;
        let refId = query.get("refId");

        dispatch(registerRequest({ formData, refId }));
      }
    },
  });

  useEffect(() => {
    if (registerError) {
      toast.error(registerError, {
        duration: 3000,
      });
    }
  }, [registerError]);

  useEffect(() => {
    if (registerSuccess) {
      // toast.success(registerSuccess, {
      //   duration: 3000,
      // });

      history.push("/dashboard/home");

      dispatch(clearRegisterSuccess());
    }
  }, [registerSuccess]);

  useEffect(() => {
    let refId = query.get("refId");
    if (refId) {
      setUserRef(refId);
      dispatch(getUserRequest(refId));
    }
  }, [getUserRequest]);

  useEffect(() => {
    if (getUserError) {
      setUserRef("");
    }
  }, [getUserError]);

  return (
    <WebPageWrapper>
      <WebPageNav />

      <div className=" pt-20 md:pt-40 pb-32 px-4">
        <div className="max-w-xl mx-auto profit-calculator-wrapper relative z-10 py-10 ">
          <h1 className="text-center text-white text-2xl md:text-4xl font-head font-semibold max-w-2xl mx-auto">
            Welcome To <br />{" "}
            <span className="text-primary">Tencent Investment</span>{" "}
          </h1>

          <div className=" border-t-2 border-formBorder w-full px-4 md:px-32 mt-8">
            <form className="py-16 flex flex-col gap-y-3">
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">First name:</h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="First name"
                  name="firstName"
                  {...formik.getFieldProps("firstName")}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <FormError message={formik.errors.firstName} />
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">Last name:</h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Last name"
                  {...formik.getFieldProps("lastName")}
                  name="lastName"
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <FormError message={formik.errors.lastName} />
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">Email:</h5>
                <input
                  type="email"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  name="email"
                />
                {formik.errors.email && formik.touched.email && (
                  <FormError message={formik.errors.email} />
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">Phone:</h5>
                <input
                  type="number"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Phone"
                  {...formik.getFieldProps("phone")}
                  name="phone"
                />
                {formik.errors.phone && formik.touched.phone && (
                  <FormError message={formik.errors.phone} />
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">
                  Bitcoin Address(not required):
                </h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Bitcoin Address"
                  {...formik.getFieldProps("bitcoinAddress")}
                  name="bitcoinAddress"
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">
                  Ethereum Address(not required):
                </h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Ethereum Address"
                  {...formik.getFieldProps("ethereumAddress")}
                  name="ethereumAddress"
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">
                  USDT(TRC20) Address(not required):
                </h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="USDT(TRC20) Address"
                  {...formik.getFieldProps("usdtAddress")}
                  name="usdtAddress"
                />
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">Password:</h5>

                <input
                  type="text"
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}
                  name="password"
                />
                {formik.errors.password && formik.touched.password && (
                  <FormError message={formik.errors.password} />
                )}
              </div>
              <div className="flex flex-col items-center justify-center gap-y-2">
                <h5 className="font-body text-white">Confirm Password:</h5>
                <input
                  type="text"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="py-3 px-4 w-full rounded-md bg-dark border border-formBorder text-white   focus:outline-none focus:border-primary placeholder-gray-200::placeholder placeholder-opacity-75 transition-all duration-500"
                  placeholder="Confirm password"
                  name="confirmPassword"
                />
              </div>

              <div className="mt-10 flex flex-col gap-y-4">
                {userRef && (
                  <h1 className="text-white font-body text-base text-center">
                    Your Upline: {singleUser.firstName} {singleUser.lastName}
                  </h1>
                )}

                <div className="flex  gap-x-2 text-white items-center justify-center">
                  <input
                    className="w-4"
                    type="checkbox"
                    value={checked}
                    onClick={() => setChecked(!checked)}
                  ></input>
                  <p>
                    {" "}
                    agree with{" "}
                    <Link to="/rules" className="text-blue">
                      Terms and conditions
                    </Link>
                  </p>
                </div>

                <Link to="/login">
                  {" "}
                  <h5 className="text-sm text-blue font-body underline  text-center ">
                    Already have an account? Login
                  </h5>{" "}
                </Link>
              </div>

              <div className="w-32 mt-5 flex justify-center mx-auto">
                <div></div>
                <FormButtonPrimary
                  text={"Register"}
                  action={formik.handleSubmit}
                  loading={registerLoading}
                  disabled={!formik.isValid}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Register;
