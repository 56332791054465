import {
  CREATE_WITHDRAW_FAIL,
  CREATE_WITHDRAW_LOADING,
  CREATE_WITHDRAW_REQUEST,
  CREATE_WITHDRAW_SUCCESS,
  CLEAR_CREATE_WITHDRAW_SUCCESS,
  GET_WITHDRAWS_REQUEST,
  GET_WITHDRAWS_SUCCESS,
  GET_WITHDRAWS_FAIL,
  GET_WITHDRAWS_LOADING,
  GET_USER_WITHDRAWS_REQUEST,
  GET_USER_WITHDRAWS_SUCCESS,
  GET_USER_WITHDRAWS_FAIL,
  GET_USER_WITHDRAWS_LOADING,
  UPDATE_WITHDRAW_REQUEST,
  UPDATE_WITHDRAW_SUCCESS,
  CLEAR_UPDATE_WITHDRAW_SUCCESS,
  UPDATE_WITHDRAW_FAIL,
  UPDATE_WITHDRAW_LOADING,
} from "../types";

export const createWithdrawRequest = (prop) => {
  return {
    type: CREATE_WITHDRAW_REQUEST,
    payload: prop,
  };
};

export const createWithdrawSuccess = (prop) => {
  return {
    type: CREATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};
export const clearCreateWithdrawSuccess = (prop) => {
  return {
    type: CLEAR_CREATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};

export const createWithdrawFailure = (errors) => {
  return {
    type: CREATE_WITHDRAW_FAIL,
    payload: { errors },
  };
};

export const createWithdrawLoading = (loading) => {
  return {
    type: CREATE_WITHDRAW_LOADING,
    payload: loading,
  };
};

export const getWithdrawsRequest = (prop) => {
  return {
    type: GET_WITHDRAWS_REQUEST,
    payload: prop,
  };
};

export const getWithdrawsSuccess = (prop) => {
  return {
    type: GET_WITHDRAWS_SUCCESS,
    payload: prop,
  };
};

export const getWithdrawsFailure = (errors) => {
  return {
    type: GET_WITHDRAWS_FAIL,
    payload: { errors },
  };
};

export const getWithdrawsLoading = (loading) => {
  return {
    type: GET_WITHDRAWS_LOADING,
    payload: loading,
  };
};

export const getUserWithdrawsRequest = (prop) => {
  return {
    type: GET_USER_WITHDRAWS_REQUEST,
    payload: prop,
  };
};

export const getUserWithdrawsSuccess = (prop) => {
  return {
    type: GET_USER_WITHDRAWS_SUCCESS,
    payload: prop,
  };
};

export const getUserWithdrawsFailure = (errors) => {
  return {
    type: GET_USER_WITHDRAWS_FAIL,
    payload: { errors },
  };
};

export const getUserWithdrawsLoading = (loading) => {
  return {
    type: GET_USER_WITHDRAWS_LOADING,
    payload: loading,
  };
};

export const updateWithdrawRequest = (prop) => {
  return {
    type: UPDATE_WITHDRAW_REQUEST,
    payload: prop,
  };
};

export const updateWithdrawSuccess = (prop) => {
  return {
    type: UPDATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};

export const clearUpdateWithdrawSuccess = (prop) => {
  return {
    type: CLEAR_UPDATE_WITHDRAW_SUCCESS,
    payload: prop,
  };
};

export const updateWithdrawFailure = (errors) => {
  return {
    type: UPDATE_WITHDRAW_FAIL,
    payload: { errors },
  };
};

export const updateWithdrawLoading = (loading) => {
  return {
    type: UPDATE_WITHDRAW_LOADING,
    payload: loading,
  };
};
