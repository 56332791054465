import React from "react";
import { format } from "date-fns";

export const InvestmentCard = ({ investment }) => {
  return (
    <div
      className={`relative grid grid-cols-3  items-center text-xs md:text-base border-b   border-white text-white font-body py-3 `}
    >
      <div>
        <h5>{investment.planName && investment.planName}</h5>
        <h5 className="mt-2">
          {investment.createdAt &&
            format(Date.parse(investment.createdAt), "MM/dd/yyyy")}
        </h5>
      </div>

      <div>
        <h5>${investment.amount && investment.amount}</h5>
        <h5 className="mt-2">{investment.status && investment.status}</h5>
      </div>

      <div>
        <h5>{investment.expectedReturn && investment.expectedReturn}</h5>
        <h5 className="mt-2">{investment.chain && investment.chain}</h5>
      </div>
    </div>
  );
};

export const WithdrawalCard = ({ data }) => {
  return (
    <div
      className={`relative grid grid-cols-3  items-center text-xs md:text-base border-b   border-white text-white font-body py-3 `}
    >
      <div>
        <h5>${data.amount && data.amount}</h5>
        <h5 className="mt-2">
          {data.createdAt && format(Date.parse(data.createdAt), "MM/dd/yyyy")}
        </h5>
      </div>
      <div>
        <h5 className="">Status:</h5>
        <h5 className="mt-2">{data.status && data.status}</h5>
      </div>

      <div>
        <h5 className="">Account:</h5>
        <h5 className="mt-2">{data.chain && data.chain}</h5>
      </div>
    </div>
  );
};
