import React from "react";

export const DashboardPlanCard = ({
  planName,
  minAmount,
  maxAmount,
  profitPercent,
}) => {
  return (
    <div>
      <div
        className={`text-center border-y text-xs md:text-base  border-white text-white font-body py-3 `}
      >
        <h5>{planName}</h5>
      </div>
      <div
        className={`grid grid-cols-3 text-xs md:text-base justify-items-center bg-primary border-b  border-white text-white font-body py-3  `}
      >
        <h5>Plan</h5>
        <h5>Spent Amount($)</h5>
        <h5>Daily Profit(%)</h5>
      </div>
      <div
        className={`grid grid-cols-3 text-xs md:text-base justify-items-center border-b  border-white text-white font-body py-3 `}
      >
        <h5>Plan</h5>
        <h5>
          ${minAmount} - ${maxAmount}{" "}
        </h5>
        <h5>{profitPercent}</h5>
      </div>
    </div>
  );
};
