import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import {
  CREATE_INVESTMENT_REQUEST,
  GET_INVESTMENT_REQUEST,
  GET_USER_INVESTMENTS_REQUEST,
  UPDATE_INVESTMENT_REQUEST,
} from "../types";
import { clientErrorMessage, delay } from "./reusable";

import {
  createInvestmentFailure,
  createInvestmentLoading,
  createInvestmentSuccess,
  getUserInvestmentsFailure,
  getUserInvestmentsLoading,
  getUserInvestmentsSuccess,
  getInvestmentFailure,
  getInvestmentLoading,
  getInvestmentSuccess,
  updateInvestmentLoading,
  updateInvestmentSuccess,
  updateInvestmentFailure,
} from "../action";

const ajaxDBCalls = {
  getUserInvestments: async (id) => {
    const response = await axios.get(`/investment/user/${id}`);
    return response;
  },
  getInvestment: async (id) => {
    const response = await axios.get(`/investment/${id}`);
    return response;
  },
  createInvestment: async (formData) => {
    const response = await axios.post(`/investment/create`, formData);
    return response;
  },
  updateInvestment: async ({ formData, userId, id, token }) => {
    const response = await axios.put(`/investment/${userId}/${id}`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
};

function* getUserInvestments({ payload }) {
  try {
    yield put(getUserInvestmentsLoading(true));

    const res = yield call(ajaxDBCalls.getUserInvestments, payload);

    yield put(getUserInvestmentsSuccess(res.data));

    yield put(getUserInvestmentsLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUserInvestmentsFailure(errorMessage));
    yield put(getUserInvestmentsLoading(false));
    yield delay();
    yield put(getUserInvestmentsFailure(""));
  }
}

function* getInvestment({ payload }) {
  try {
    yield put(getInvestmentLoading(true));

    const res = yield call(ajaxDBCalls.getInvestment, payload);

    yield put(getInvestmentSuccess(res.data));

    yield put(getInvestmentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getInvestmentFailure(errorMessage));
    yield put(getInvestmentLoading(false));
    yield delay();
    yield put(getInvestmentFailure(""));
  }
}

function* createInvestment({ payload }) {
  try {
    yield put(createInvestmentLoading(true));

    const res = yield call(ajaxDBCalls.createInvestment, payload);

    yield put(createInvestmentSuccess(res.data));

    yield put(createInvestmentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    console.log(err);
    if (err.response) {
      console.log(err);
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(createInvestmentFailure(errorMessage));
    yield put(createInvestmentLoading(false));
    yield delay();
    yield put(createInvestmentFailure(""));
  }
}

function* updateInvestment({ payload }) {
  try {
    yield put(updateInvestmentLoading(true));

    const res = yield call(ajaxDBCalls.updateInvestment, payload);

    yield put(updateInvestmentSuccess(res.data));

    yield put(updateInvestmentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(updateInvestmentFailure(errorMessage));
    yield put(updateInvestmentLoading(false));
    yield delay();
    yield put(updateInvestmentFailure(""));
  }
}

//Watchers
function* getUserInvestmentsWatcher() {
  yield takeLatest(GET_USER_INVESTMENTS_REQUEST, getUserInvestments);
}
function* getInvestmentWatcher() {
  yield takeLatest(GET_INVESTMENT_REQUEST, getInvestment);
}
function* createInvestmentWatcher() {
  yield takeLatest(CREATE_INVESTMENT_REQUEST, createInvestment);
}
function* updateInvestmentWatcher() {
  yield takeLatest(UPDATE_INVESTMENT_REQUEST, updateInvestment);
}

export default function* investmentSagas() {
  yield spawn(getUserInvestmentsWatcher);
  yield spawn(getInvestmentWatcher);
  yield spawn(createInvestmentWatcher);
  yield spawn(updateInvestmentWatcher);
}
