import React from "react";
import { useHistory } from "react-router-dom";

export const PlanCard = ({ data, isLoggedIn }) => {
  const history = useHistory();

  const handleClick = () => {
    if (isLoggedIn) history.push("/dashboard/makedeposit");
    else history.push("/login");
  };
  return (
    <div className="text-center py-6 px-4 relative z-10 border-[3px] rounded-lg border-primary card-box-shadow">
      <img
        className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
        src="/img/web/cardbg.png"
      />
      <div className="relative">
        <h2 className="font-head text-3xl text-primary">
          {data.planName && data.planName}
        </h2>
        <h4 className="text-primary font-head text-2xl mt-8">
          {data.profitProduction && data.profitProduction}%
        </h4>

        <div className=" flex flex-col my-8">
          <p className="font-body card-list-border w-full text-base mx-auto">
            Return {data.profitProduction && data.profitProduction}%
          </p>
          <p className="font-body card-list-border w-full text-base mx-auto">
            In 24 hours
          </p>
          <p className="font-body card-list-border  w-full text-base mx-auto">
            10% Referral Commission
          </p>
        </div>

        <h5 className="font-head text-primary text-xl">
          ${data.minAmount && data.minAmount} - $
          {data.maxAmount && data.maxAmount}
        </h5>

        <div className="w-32 mx-auto my-10">
          <div
            onClick={handleClick}
            className=" bg-primary text-dark w-full rounded-lg font-body py-2 flex item-center justify-center cursor-pointer"
          >
            Invest Now
          </div>
        </div>
      </div>
    </div>
  );
};
