import React from "react";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageButton } from "../../../components/buttons";
import { MdContentCopy } from "react-icons/md";
import { AiFillLock } from "react-icons/ai";
import { WhyCard } from "../../../components/cards/whyCard";
import {
  FaBus,
  FaUserLock,
  FaPeopleArrows,
  FaServer,
  FaLock,
} from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { BsDatabaseLock } from "react-icons/bs";

const whyChooseUsData = [
  {
    Icon: MdContentCopy,
    head: "Legal Company",
    body: "Our company conducts absolutely legal activities in the legal field. We are certified to operate investment business, we are legal and safe.",
  },
  {
    Icon: AiFillLock,
    head: "High reliability",
    body: "We are trusted by a huge number of people. We are working hard constantly to improve the level of our security system and minimize possible risks.",
  },
  {
    Icon: FaUserLock,
    head: "Anonymity",
    body: "Anonymity and using cryptocurrency as a payment instrument. In the era of electronic money – this is one of the most convenient ways of cooperation.",
  },
  {
    Icon: FaBus,
    head: "Quick Withdrawal",
    body: "Our all retreats are treated spontaneously once requested. There are high maximum limits. The minimum withdrawal amount is only $10 .",
  },
  {
    Icon: FaPeopleArrows,
    head: "Referral Program",
    body: "We are offering a certain level of referral income through our referral program. you can increase your income by simply refer a few people.",
  },
  {
    Icon: BiSupport,
    head: "24/7 Support",
    body: "We provide 24/7 customer support through e-mail and telegram. Our support representatives are periodically available to elucidate any difficulty.",
  },
  {
    Icon: FaServer,
    head: "Dedicated Server",
    body: "We are using a dedicated server for the website which allows us exclusive use of the resources of the entire server.",
  },
  {
    Icon: FaLock,
    head: "SSL Secured",
    body: "Comodo Essential-SSL Security encryption confirms that the presented content is genuine and legitimate.",
  },
  {
    Icon: BsDatabaseLock,
    head: "DDOS Protection ",
    body: "We are using one of the most experienced, professional, and trusted DDoS Protection and mitigation provider.",
  },
];

const About = () => {
  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"About"} />

      <div className="relative md:h-[90vh] z-10">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/img/web/plantbg.jpg"
        />

        <div className="container mx-auto pb-10 md:pb-0 pt-24 px-4 relative grid md:grid-cols-2">
          <div></div>
          <div className="flex flex-col space-y-6">
            <h1 className="text-white font-head text-3xl md:text-5xl">
              About <span className="text-primary">Us</span>
            </h1>

            <p className="text-white font-body text-base">
              The Tencent Investment is an international infrastructural
              investment company with a highly skilled professional team with
              years of trading experience. We offer and help to set up a
              detailed trading plan for each of your investors including
              researching profitable cryptos, buy/sell orders, minimize losses
              to its lowest, and trading channels. Tencent Investment works are
              on high expertise of traders’ internal financial crypto market,
              transparency and longtime relation with our customers.
            </p>
            <p className="text-white font-body text-base">
              The company has been in operation Since 2014 and ventured into
              crypto investments in the year 2018, it is a leading bitcoin
              investment company providing investors the ability to instantly
              seamlessly invest and earn bitcoin using the best bitcoin
              investment strategies (BST method).
            </p>

            <div className="w-auto mr-auto">
              <WebPageButton text={"Company Certificate"} />
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-auto z-10">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/img/web/whybg.jpg"
        />

        <div className="h-full backdrop-brightness-50 backdrop-blur-sm">
          <div className="container mx-auto py-24 px-4 relative text-white ">
            <h1 className=" font-head text-3xl md:text-5xl text-center">
              Why Choose{" "}
              <span className="text-primary">Tencent Investment</span>
            </h1>
            <p className="text-center my-6 max-w-xl mx-auto text-base font-body">
              Our goal is to provide our investors with a reliable source of
              high income, while minimizing any possible risks and offering a
              high-quality service.
            </p>

            <div className="grid md:grid-cols-3 gap-12">
              {whyChooseUsData.map(({ Icon, head, body }) => (
                <WhyCard head={head} Icon={Icon} body={body} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default About;
