import React, { useEffect, useState } from "react";
import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageHeader } from "../../../components/Headers/webPageHeader";
import { DashboardNav } from "../../../components/Nav/dashboardNav";
import {
  getReferersRequest,
  getUserInvestmentsRequest,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import { InvestmentCard } from "../../../components/cards/investmentCard";

const Referrals = () => {
  const websiteURL = "https://tencentinvestment.com/register";

  const dispatch = useDispatch();

  const {
    user,
    getReferersError,
    getReferersSuccess,
    getReferersLoading,
    token,
    referers,
  } = useSelector((state) => {
    const {
      success: { getReferers: getReferersSuccess },
      errors: { getReferers: getReferersError },
    } = state.ajaxStatuses;

    const { getReferersLoading } = state.loadingIndicator;

    const { user, token, referers } = state.userData;

    return {
      user,
      getReferersError,
      getReferersSuccess,
      getReferersLoading,
      token,
      referers,
    };
  });

  useEffect(() => {
    if (getReferersError) {
      toast.error(getReferersError, {
        duration: 3000,
      });
    }
  }, [getReferersError]);

  useEffect(() => {
    if (getReferersSuccess) {
      console.log("referers fetched successfully");
    }
  }, [getReferersSuccess]);

  useEffect(() => {
    dispatch(getReferersRequest(user._id));
  }, [getReferersRequest]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${websiteURL}${user.referralLink}`);

    toast.success("Referral link copied", {
      duration: 3000,
      position: "bottom-center",
    });
  };

  return (
    <WebPageWrapper>
      <WebPageNav />
      <WebPageHeader text={"Referrals"} />

      <div className="flex flex-col md:flex-row container mx-auto px-4 py-12 md:py-24 gap-x-10 gap-y-10">
        <DashboardNav />
        <div className=" mx-auto profit-calculator-wrapper relative z-10 py-10 px-6  w-[100%] md:w-[75%]">
          <h3 className="text-white font-body text-xl md:text-2xl text-center">
            Referrals
          </h3>

          <div className="mt-4 text-white">
            <p className=" text-sm font-body font-light">Referral link:</p>
            <div onClick={() => copyToClipboard()} className="flex gap-x-2 ">
              <p className=" text-xs font-body ">
                {websiteURL}
                {user.referralLink}
              </p>
              <MdContentCopy className="cursor-pointer" />
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-x-2 text-white">
            <p className=" text-sm font-body font-light text-white ">
              Downliners:
            </p>

            <div className=" items-center flex justify-between">
              <p className="text-fadedGray flex  font-body font-light text-sm">
                Referred users
              </p>
              <p className="text-fadedGray flex  font-body font-light text-sm">
                Active
              </p>
            </div>

            {getReferersLoading ? (
              <div className="flex items-center justify-center gap-y-2 mt-3">
                <CircularProgress size={20} className="text-primary" />
              </div>
            ) : (
              <div className="flex flex-col gap-y-2 mt-3 text-white">
                {referers.map(
                  (
                    {
                      firstName,
                      lastName,
                      isInvested,
                      bitcoinBalance,
                      ethereumBalance,
                      usdtBalance,
                    },
                    index
                  ) => (
                    <div key={index} className="flex justify-between ">
                      <p className="text-fadedGray text-sm font-body font-light">
                        {firstName} {lastName}
                      </p>
                      <p className="text-fadedGray text-sm font-body font-light">
                        {isInvested ? "Yes" : "NO"}
                      </p>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Referrals;
