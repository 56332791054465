import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaDice, FaWallet } from "react-icons/fa";
import { WorkCard } from "../../../components/cards/workCard";
import { PlanCard } from "../../../components/cards/planCard";

import { WebPageWrapper } from "../../../components/PageWrapper/webPageWrapper";
import { WebPageNav } from "../../../components/Nav/webPageNav";
import { WebpageFooter } from "../../../components/Footer/WebpageFooter";
import { WebPageButton } from "../../../components/buttons";
import { PlanSection } from "../../../components/PlanSection/planSection";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <WebPageWrapper>
      <WebPageNav />
      <section className="h-screen z-10 relative">
        <img
          className="object-cover absolute h-full md:h-screen top-0 left-0"
          src="/img/web/showcase.jpg"
        />

        <div className="h-screen relative grid md:grid-cols-2 container mx-auto px-4 backdrop-brightness-50 pt-20 md:pt-36 pb-4">
          <div>
            <h1 className="text-4xl md:text-5xl text-white  font-head max-w-lg">
              Invest for the future in a stable and reliable platform
              <span className=" text-primary text-4xl md:text-6xl">
                {"  "} and welcome on board
              </span>
            </h1>
            <p className="text-white font-body text-base md:text-lg my-10 md:my-4 tracking-wider">
              To financial stability and freedom Invest in an Industry Leader,
              Professional, and Reliable Company. We provide you with the most
              necessary features that will make your experience better. Not only
              we guarantee the fastest and the most exciting returns on your
              investments, but we also guarantee the security of your
              investment.
            </p>

            <div className="w-32">
              <Link to="/login">
                <WebPageButton text={"Sign Up"} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="md:h-[90vh] relative md:pt-14">
        <img
          className="object-cover absolute w-full h-full top-0"
          src="/img/web/workbg.jpg"
        />
        <div className="relative container mx-auto px-4 py-12 text-white">
          <h1 className=" text-center text-3xl md:text-5xl font-head font-semibold">
            How <span className="text-primary">Tencent Investment</span> Works
          </h1>
          <p className="text-center my-6 max-w-2xl mx-auto text-base font-body">
            Get involved in our tremendous platform and Invest. We will utilize
            your money and give you profit in your wallet automatically.
          </p>

          <div className="grid gap-y-10 md:grid-cols-3 place-items-center mx-auto max-w-6xl mt-20">
            <WorkCard
              Icon={BsFillPersonFill}
              number={1}
              text={"Sign Up And Deposit"}
            />
            <WorkCard Icon={FaDice} number={2} text={"Invest To Plan"} />
            <WorkCard Icon={FaWallet} number={3} text={"Get Profit"} />
          </div>
        </div>
      </section>

      <section className="relative bg-[#101113]">
        <PlanSection />
        <div className="container mx-auto px-4 relative py-12 text-white z-10 ">
          <h1 className=" text-center text-2xl md:text-5xl font-head font-semibold max-w-2xl mx-auto">
            10% Referral Commission{" "}
            <span className="text-primary">
              {" "}
              On Every First Deposit Made By Your Downline
            </span>
          </h1>

          <div className="max-w-xl mx-auto profit-calculator-wrapper mt-10 p-10">
            <img className="mx-auto " src="/img/web/10.png" />
            <p className="text-center font-body">
              On Referral of above 50 active referrals you will be eligible to
              earn $2500 monthly salary and a package from the company to your
              doorstep.
            </p>
          </div>

          <div className="max-w-4xl h-[250px] mx-auto relative rounded-lg mt-20 card-box-shadow ">
            <img
              className="mx-auto w-full h-full absolute object-cover rounded-lg"
              src="/img/web/startedbg.jpg"
            />

            <div className="relative py-10">
              <h1 className="text-center text-2xl md:text-4xl font-head font-semibold max-w-2xl mx-auto">
                Get Started Today With Us
              </h1>
              <p className="text-center text-base font-body max-w-xl mx-auto my-6">
                Invest for the future in a stable and reliable platform and
                welcome on board to financial stability and freedom.
              </p>

              <div className="w-32 mx-auto ">
                <div className=" bg-primary text-dark w-full rounded-lg font-body py-2 flex item-center justify-center">
                  Join Us
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WebpageFooter />
    </WebPageWrapper>
  );
};

export default Home;
